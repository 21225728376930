import { adminApi } from '@/api';
import router from '@/router';
import { ActionContext } from 'vuex';
import {
  IUserProfileCreate,
  IUserProfileUpdate,
  IProductCreate,
  IOrderUpdate,
  IProductUpdate,
  IOrderCreate,
  IFulfillmentTemplate,
  ICategoryCreate,
  ICategoryUpdate,
  IBundleCreate,
  IBundleUpdate,
  IBundleProductCreate,
  ICalendar,
  IShopSetting,
  IPromotion,
  IPaymentMethod,
} from '@/interfaces';

import { AdminState } from '@/store/admin/state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
  commitSetUsers,
  commitSetUser,
  commitSetProducts,
  commitSetOrders,
  commitDeleteProduct,
  commitSetOrder,
  commitSetPlans,
  commitSetFulfillmentTemplates,
  commitSetProduct,
  commitSetPaymentMethods,
  commitSetCategories,
  commitSetCategory,
  commitDeleteCategory,
  commitSetBundle,
  commitDeleteBundle,
  commitSetBundles,
  commitSetVariantsProducts,
  commitSetCalendar,
  commitSetCalendars,
  commitSetShopSetting,
  commitSetSubscription,
  commitSetInvoices,
} from '@/store/admin/mutations';

import {
  commitAddNotification,
  commitRemoveNotification,
} from '@/store/main/mutations';
import { dispatchCheckApiError } from '@/store/main/actions';
import { State } from '@/store/state';
import { OrderStatuses } from '@/config';

type AdminContext = ActionContext<AdminState, State>;

export const actions = {
  async actionGetDomain(context: AdminContext) {
    const response = await adminApi.getDomain(context.rootState.main.token);
    return response.data[0];
  },

  async actionGetUsers(context: AdminContext) {
    try {
      const response = await adminApi.getUsers(context.rootState.main.token);
      if (response) {
        commitSetUsers(context, response.data);
      }
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateUser(
    context: AdminContext,
    payload: { id: string; user: IUserProfileUpdate }
  ) {
    try {
      const loadingNotification = { content: 'đang lưu', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          adminApi.updateUser(
            context.rootState.main.token,
            payload.id,
            payload.user
          ),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitSetUser(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Đã cập nhật thông tin người dùng',
        color: 'success',
      });
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateUser(context: AdminContext, payload: IUserProfileCreate) {
    try {
      const loadingNotification = { content: 'đang lưu', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          adminApi.createUser(context.rootState.main.token, payload),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitSetUser(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Đã tạo người dùng mới thành công',
        color: 'success',
      });
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionGetShops(context: AdminContext) {
    try {
      const response = await adminApi.getUsers(context.rootState.main.token);
      if (response) {
        commitSetUsers(context, response.data);
      }
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateShop(
    context: AdminContext,
    payload: { id: string; user: IUserProfileUpdate }
  ) {
    try {
      const loadingNotification = { content: 'đang lưu', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          adminApi.updateUser(
            context.rootState.main.token,
            payload.id,
            payload.user
          ),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitSetUser(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Đã cập nhật thông tin người dùng',
        color: 'success',
      });
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },
  // get a single product
  async actionGetProduct(context: AdminContext, payload: { id: string }) {
    try {
      const response = await adminApi.getProduct(
        context.rootState.main.token,
        payload.id
      );
      if (response) {
        commitSetProduct(context, response.data);
      }
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },
  // get a single product with all its related data
  async actionGetProductMeta(context: AdminContext, payload: { id: string }) {
    try {
      const response = await adminApi.getProduct(
        context.rootState.main.token,
        payload.id
      );
      if (response) {
        commitSetProduct(context, response.data);
      }
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetProducts(context: AdminContext) {
    try {
      const response = await adminApi.getProducts(context.rootState.main.token);
      if (response) {
        commitSetProducts(context, response.data);
      }
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetVariantsAsProducts(context: AdminContext) {
    try {
      const response = await adminApi.getVariantsAsProducts(
        context.rootState.main.token
      );
      if (response) {
        commitSetVariantsProducts(context, response.data);
      }
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionUpdateProduct(
    context: AdminContext,
    payload: { productId: string; product: IProductUpdate }
  ) {
    try {
      const loadingNotification = { content: 'đang lưu', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          adminApi.updateProduct(
            context.rootState.main.token,
            payload.productId,
            payload.product
          ),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitSetProduct(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Sản phẩm đã được cập nhật',
        color: 'success',
      });
      dispatchRouteUpdatedProduct(context);
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateProductMeta(
    context: AdminContext,
    payload: { productId: string; product: IProductUpdate }
  ) {
    try {
      const loadingNotification = { content: 'đang lưu', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          adminApi.updateProductMeta(
            context.rootState.main.token,
            payload.productId,
            payload.product
          ),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitSetProduct(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Sản phẩm đã được cập nhật thành công',
        color: 'success',
      });
      dispatchRouteUpdatedProduct(context);
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionCreateProductMeta(
    context: AdminContext,
    payload: { product: IProductCreate; categoryId: string }
  ) {
    try {
      const loadingNotification = { content: 'đang lưu', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          adminApi.createProductMeta(context.rootState.main.token, payload),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitSetProduct(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Sản phẩm đã được tạo thành công',
        color: 'success',
      });
      dispatchRouteCreateProduct(context);
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeleteProduct(context: AdminContext, payload: { id: string }) {
    try {
      const loadingNotification = { content: 'đang xoá', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          adminApi.deleteProduct(context.rootState.main.token, payload.id),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Sản phẩm đã được xoá thành công',
        color: 'success',
      });
      commitDeleteProduct(context, payload.id);
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },
  // get orders
  async actionGetOrders(context: AdminContext) {
    try {
      const response = await adminApi.getOrders(context.rootState.main.token);
      if (response) {
        commitSetOrders(context, response.data);
      }
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },
  // get order
  async actionGetOrder(context: AdminContext, payload: { id: string }) {
    try {
      const response = await adminApi.getOrder(
        context.rootState.main.token,
        payload.id
      );
      if (response) {
        commitSetOrder(context, response.data);
      }
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  // get plans
  async actionGetPlans(context: AdminContext) {
    try {
      const response = await adminApi.getPlans(context.rootState.main.token);
      if (response) {
        commitSetPlans(context, response.data);
      }
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  // get invoices
  async actionGetInvoices(context: AdminContext) {
    try {
      const response = await adminApi.getInvoices(context.rootState.main.token);
      if (response) {
        commitSetInvoices(context, response.data);
      }
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  // get active subscription
  async actionGetSubscription(context: AdminContext) {
    try {
      const response = await adminApi.getActiveSubscription(
        context.rootState.main.token
      );
      if (response) {
        commitSetSubscription(context, response.data);
      }
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  // update order status
  async actionUpdateOrderStatus(
    context: AdminContext,
    payload: { orderId: string; order: IOrderUpdate; silentMode: boolean }
  ) {
    const loadingNotification = { content: 'đang lưu', showProgress: true };
    try {
      if (payload.silentMode === false) {
        commitAddNotification(context, loadingNotification);
      }

      const response = (
        await Promise.all([
          adminApi.updateOrderStatus(context.rootState.main.token, payload),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitSetOrder(context, response.data);
      if (payload.silentMode === false) {
        commitRemoveNotification(context, loadingNotification);
        if (payload.order.status === OrderStatuses.PAID) {
          commitAddNotification(context, {
            content: 'Đang gửi biên lai tới email ' + payload.order.buyer_email,
            color: 'success',
          });
        } else if (payload.order.status === OrderStatuses.FULFILLED) {
          commitAddNotification(context, {
            content:
              'Đánh dấu đã giao hàng cho đơn hàng ' + payload.order.public_id,
            color: 'success',
          });
        } else if (payload.order.status === OrderStatuses.UNPAID) {
          commitAddNotification(context, {
            content:
              'Đánh dấu chưa thanh toán cho đơn hàng ' +
              payload.order.public_id,
            color: 'success',
          });
        } else if (payload.order.status === OrderStatuses.ARCHIVED) {
          commitAddNotification(context, {
            content:
              'Đánh dấu đã lưu trữ cho đơn hàng ' + payload.order.public_id,
            color: 'success',
          });
        }
      }
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },
  // update order meta
  async actionUpdateOrderMeta(
    context: AdminContext,
    payload: { orderId: string; order: IOrderUpdate }
  ) {
    const loadingNotification = { content: 'đang lưu', showProgress: true };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          adminApi.updateOrderMeta(context.rootState.main.token, payload),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitSetOrder(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Đơn hàng đã được cập nhật thành công',
        color: 'success',
      });
      dispatchRouteUpdatedOrder(context);
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  // create order meta
  async actionCreateOrderMeta(context: AdminContext, payload: IOrderCreate) {
    const loadingNotification = { content: 'đang lưu', showProgress: true };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          adminApi.createOrderMeta(context.rootState.main.token, payload),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitSetOrder(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Đơn hàng đã được tạo thành công',
        color: 'success',
      });
      dispatchRouteUpdatedOrder(context);
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  // action get delivery method template
  async actionGetFulfillmentTemplates(context: AdminContext) {
    try {
      const response = await adminApi.getFulfillmentTemplates(
        context.rootState.main.token
      );
      if (response) {
        commitSetFulfillmentTemplates(context, response.data);
      }
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  // action update delivery method templates
  async actionUpdateFulfillmentTemplates(
    context: AdminContext,
    payload: IFulfillmentTemplate[]
  ) {
    const loadingNotification = { content: 'đang lưu', showProgress: true };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          adminApi.updateFulfillmentTemplates(
            context.rootState.main.token,
            payload
          ),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitSetFulfillmentTemplates(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Đã cập nhật phương thức giao hàng thành công',
        color: 'success',
      });
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  // action get payment methods
  async actionGetPaymentMethods(context: AdminContext) {
    try {
      const response = await adminApi.getPaymentMethods(
        context.rootState.main.token
      );
      if (response) {
        commitSetPaymentMethods(context, response.data);
      }
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionGetCategories(context: AdminContext) {
    try {
      const response = await adminApi.getCategories(
        context.rootState.main.token
      );
      if (response) {
        commitSetCategories(context, response.data);
      }
    } catch (error: any) {
      dispatchCheckApiError(context, error);
    }
  },

  async actionCreateCategory(
    context: AdminContext,
    payload: { category_in: ICategoryCreate; product_ids: string[] }
  ) {
    const loadingNotification = { content: 'đang lưu', showProgress: true };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          adminApi.createCategory(context.rootState.main.token, payload),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitSetCategory(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Đã tạo danh mục thành công',
        color: 'success',
      });
      dispatchRouteUpdatedCategory(context);
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionUpdateCategory(
    context: AdminContext,
    payload: {
      category_id: string;
      category_in: ICategoryUpdate;
      product_ids: string[];
    }
  ) {
    const loadingNotification = { content: 'đang lưu', showProgress: true };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          adminApi.updateCategory(context.rootState.main.token, payload),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitSetCategory(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Đã cập nhật danh mục thành công',
        color: 'success',
      });
      dispatchRouteUpdatedCategory(context);
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionDeleteCategory(context: AdminContext, categoryId: string) {
    const loadingNotification = { content: 'đang xoá', showProgress: true };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          adminApi.deleteCategory(context.rootState.main.token, categoryId),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitDeleteCategory(context, categoryId);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Đã xóa danh mục thành công',
        color: 'success',
      });
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionCreateBundleMeta(
    context: AdminContext,
    data: {
      payload: {
        bundle_in: IBundleCreate;
        bundled_products_in: IBundleProductCreate[];
      };
      category_id: string;
    }
  ) {
    const loadingNotification = { content: 'đang lưu', showProgress: true };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          adminApi.createBundle(context.rootState.main.token, data),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitSetBundle(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Đã tạo gói sản phẩm thành công',
        color: 'success',
      });
      dispatchRouteUpdatedBundle(context);
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeleteBundle(context: AdminContext, bundleId: string) {
    try {
      const response = await adminApi.deleteBundle(
        context.rootState.main.token,
        bundleId
      );
      if (response) {
        commitDeleteBundle(context, bundleId);
      }
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionUpdateBundle(
    context: AdminContext,
    payload: { bundleId: string; bundle: IBundleUpdate }
  ) {
    const loadingNotification = { content: 'đang lưu', showProgress: true };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          adminApi.updateBundle(context.rootState.main.token, payload),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitSetBundle(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Đã cập nhật gói sản phẩm thành công',
        color: 'success',
      });
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionUpdatePaymentMethods(
    context: AdminContext,
    paymentMethods: IPaymentMethod[]
  ) {
    const loadingNotification = { content: 'đang lưu', showProgress: true };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          adminApi.updatePaymentMethods(
            context.rootState.main.token,
            paymentMethods
          ),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitSetPaymentMethods(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Đã cập nhật phương thức thanh toán thành công',
        color: 'success',
      });
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionGetBundles(context: AdminContext) {
    try {
      const response = await adminApi.getBundles(context.rootState.main.token);
      if (response) {
        commitSetBundles(context, response.data);
      }
    } catch (error: any) {
      dispatchCheckApiError(context, error);
    }
  },

  async actionGetBundle(context: AdminContext, bundleId: string) {
    try {
      const response = await adminApi.getBundle(
        context.rootState.main.token,
        bundleId
      );
      if (response) {
        commitSetBundle(context, response.data);
      }
    } catch (error: any) {
      dispatchCheckApiError(context, error);
    }
  },

  async actionGetCalendar(context: AdminContext, calendarId: string) {
    try {
      const response = await adminApi.getCalendar(
        context.rootState.main.token,
        calendarId
      );
      if (response) {
        commitSetCalendar(context, response.data);
      }
    } catch (error: any) {
      dispatchCheckApiError(context, error);
    }
  },

  async actionGetCalendars(context: AdminContext) {
    try {
      const response = await adminApi.getCalendars(
        context.rootState.main.token
      );
      if (response) {
        commitSetCalendars(context, response.data);
      }
    } catch (error: any) {
      dispatchCheckApiError(context, error);
    }
  },

  async actionCreateCalendars(context: AdminContext, calendars: ICalendar[]) {
    const loadingNotification = { content: 'đang lưu', showProgress: true };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          adminApi.createCalendars(context.rootState.main.token, calendars),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitSetCalendars(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Đã tạo lịch thành công',
        color: 'success',
      });
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionGetShopSetting(context: AdminContext) {
    try {
      const response = await adminApi.getShopSetting(
        context.rootState.main.token
      );
      if (response) {
        commitSetShopSetting(context, response.data);
      }
    } catch (error: any) {
      dispatchCheckApiError(context, error);
    }
  },

  async actionUpdateShopSetting(
    context: AdminContext,
    shopSettings: IShopSetting
  ) {
    const loadingNotification = { content: 'đang lưu', showProgress: true };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          adminApi.updateShopSetting(
            context.rootState.main.token,
            shopSettings
          ),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitSetShopSetting(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Đã cập nhật cài đặt thành công',
        color: 'success',
      });
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionDownloadFile(context: AdminContext, { url, label, type }) {
    try {
      const response = await adminApi.downloadFile(url);
      if (response) {
        const blob = new Blob([response.data], { type });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = label;
        link.click();
        URL.revokeObjectURL(link.href);
      }
    } catch (error: any) {
      dispatchCheckApiError(context, error);
    }
  },

  async actionDownloadAuthorizedFile(
    context: AdminContext,
    { url, label, type }
  ) {
    try {
      const response = await adminApi.downloadAuthorizedFile(
        context.rootState.main.token,
        url
      );
      if (response) {
        const header = response.headers['x-filename'];
        console.log(response.headers);
        const filename = header ? header : label;

        const blob = new Blob([response.data], { type });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        URL.revokeObjectURL(link.href);
      }
    } catch (error: any) {
      dispatchCheckApiError(context, error);
    }
  },

  async actionGetPromotions(context: AdminContext) {
    return await adminApi.getPromotions(context.rootState.main.token);
  },

  async actionCreatePromotion(context: AdminContext, promotion: IPromotion) {
    return await adminApi.createPromotion(
      context.rootState.main.token,
      promotion
    );
  },

  async actionUpdatePromotion(context: AdminContext, promotion: IPromotion) {
    return await adminApi.updatePromotion(
      context.rootState.main.token,
      promotion
    );
  },

  async actionDeletePromotion(context: AdminContext, promotionId: string) {
    return await adminApi.deletePromotion(
      context.rootState.main.token,
      promotionId
    );
  },

  async actionGetReportsSummary(
    context: AdminContext,
    payload: {
      startDate: string;
      endDate: string;
    }
  ) {
    const response = await adminApi.getReportsSummary(
      context.rootState.main.token,
      payload.startDate,
      payload.endDate
    );
    if (response) {
      return response.data;
    } else {
      return [];
    }
  },

  async actionGetBankList() {
    const response = await adminApi.getBankList();
    if (response) {
      return response.data.data;
    } else {
      return [];
    }
  },

  async actionGetRefundInfo(context: AdminContext) {
    const response = await adminApi.getRefundInfo(context.rootState.main.token);
    if (response) {
      return response.data;
    } else {
      return {};
    }
  },

  async actionRefund(context: AdminContext) {
    const loadingNotification = { content: 'đang xử lý', showProgress: true };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          adminApi.refund(context.rootState.main.token),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Đã hoàn tiền thành công',
        color: 'success',
      });
      return response.data;
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionGetPaymentLink(
    context: AdminContext,
    payload: { requestType: string; selectedPlanCode: string }
  ) {
    const loadingNotification = {
      content: 'Bạn sẽ được chuyển sang trang thanh toán trong giây lát',
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          adminApi.getPaymentLink(
            context.rootState.main.token,
            payload.requestType,
            payload.selectedPlanCode
          ),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];

      if (response) {
        commitRemoveNotification(context, loadingNotification);
        commitAddNotification(context, {
          content: 'Đang chuyển hương đến trang thanh toán',
          color: 'success',
        });
        // open website
        window.open(response.data, '_self');
      } else {
        return '';
      }
    } catch (error: any) {
      dispatchCheckApiError(context, error);
    }
  },

  actionRouteCreateProduct(context: AdminContext) {
    router.push('/main/products').catch((error) => error);
  },

  actionRouteUpdatedProduct(context: AdminContext) {
    router.push('/main/products').catch((error) => error);
  },

  actionRouteUpdatedOrder(context: AdminContext) {
    router.push('/main/orders').catch((error) => error);
  },
  actionRouteUpdatedCategory(context: AdminContext) {
    router.push('/main/categories').catch((error) => error);
  },
  actionRouteUpdatedBundle(context: AdminContext) {
    router.push('/main/bundles').catch((error) => error);
  },
};

const { dispatch } = getStoreAccessors<AdminState, State>('');

export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);

export const dispatchGetShops = dispatch(actions.actionGetShops);
export const dispatchUpdateShop = dispatch(actions.actionUpdateShop);
export const dispatchGetProduct = dispatch(actions.actionGetProduct);
export const dispatchGetProductMeta = dispatch(actions.actionGetProductMeta);
export const dispatchDeleteProduct = dispatch(actions.actionDeleteProduct);
export const dispatchGetProducts = dispatch(actions.actionGetProducts);
export const dispatchGetVariantsProducts = dispatch(
  actions.actionGetVariantsAsProducts
);
export const dispatchUpdateProduct = dispatch(actions.actionUpdateProduct);
export const dispatchUpdateProductMeta = dispatch(
  actions.actionUpdateProductMeta
);
export const dispatchCreateProductMeta = dispatch(
  actions.actionCreateProductMeta
);
export const dispatchGetOrders = dispatch(actions.actionGetOrders);
export const dispatchGetOrder = dispatch(actions.actionGetOrder);
// update order
export const dispatchUpdateOrderStatus = dispatch(
  actions.actionUpdateOrderStatus
);
// update order meta
export const dispatchUpdateOrderMeta = dispatch(actions.actionUpdateOrderMeta);
// create order meta
export const dispatchCreateOrderMeta = dispatch(actions.actionCreateOrderMeta);
// get delivery method templates
export const dispatchGetFulfillmentTemplates = dispatch(
  actions.actionGetFulfillmentTemplates
);
export const dispatchUpdateFulfillmentTemplates = dispatch(
  actions.actionUpdateFulfillmentTemplates
);
export const dispatchRouteCreateProduct = dispatch(
  actions.actionRouteCreateProduct
);
export const dispatchRouteUpdatedProduct = dispatch(
  actions.actionRouteUpdatedProduct
);
export const dispatchRouteUpdatedOrder = dispatch(
  actions.actionRouteUpdatedOrder
);
export const dispatchGetPaymentMethods = dispatch(
  actions.actionGetPaymentMethods
);
export const dispatchGetDomain = dispatch(actions.actionGetDomain);
export const dispatchGetCategories = dispatch(actions.actionGetCategories);
export const dispatchCreateCategory = dispatch(actions.actionCreateCategory);
export const dispatchUpdateCategory = dispatch(actions.actionUpdateCategory);
export const dispatchDeleteCategory = dispatch(actions.actionDeleteCategory);
export const dispatchRouteUpdatedCategory = dispatch(
  actions.actionRouteUpdatedCategory
);
export const dispatchCreateBundleMeta = dispatch(
  actions.actionCreateBundleMeta
);
export const dispatchRouteUpdatedBundle = dispatch(
  actions.actionRouteUpdatedBundle
);
export const dispatchDeleteBundle = dispatch(actions.actionDeleteBundle);
export const dispatchUpdateBundle = dispatch(actions.actionUpdateBundle);
export const dispatchGetBundles = dispatch(actions.actionGetBundles);
export const dispatchGetBundle = dispatch(actions.actionGetBundle);
export const dispatchGetCalendars = dispatch(actions.actionGetCalendars);
export const dispatchCreateCalendars = dispatch(actions.actionCreateCalendars);
export const dispatchGetShopSetting = dispatch(actions.actionGetShopSetting);
export const dispatchUpdateShopSetting = dispatch(
  actions.actionUpdateShopSetting
);
export const dispatchDownloadFile = dispatch(actions.actionDownloadFile);
export const dispatchDownloadAuthorizedFile = dispatch(
  actions.actionDownloadAuthorizedFile
);
export const dispatchGetPromotions = dispatch(actions.actionGetPromotions);
export const dispatchCreatePromotion = dispatch(actions.actionCreatePromotion);
export const dispatchUpdatePromotion = dispatch(actions.actionUpdatePromotion);
export const dispatchDeletePromotion = dispatch(actions.actionDeletePromotion);
export const dispatchGetReportsSummary = dispatch(
  actions.actionGetReportsSummary
);
export const dispatchGetBankList = dispatch(actions.actionGetBankList);
export const dispatchUpdatePaymentMethods = dispatch(
  actions.actionUpdatePaymentMethods
);
export const dispatchGetPaymentLink = dispatch(actions.actionGetPaymentLink);
export const dispatchGetPlans = dispatch(actions.actionGetPlans);
export const dispatchGetSubscription = dispatch(actions.actionGetSubscription);
export const dispatchGetInvoices = dispatch(actions.actionGetInvoices);
export const dispatchGetRefundInfo = dispatch(actions.actionGetRefundInfo);
export const dispatchRefund = dispatch(actions.actionRefund);
