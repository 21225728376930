<template>
  <div>
    <v-container fluid>
      <v-row>
        <draggable
          v-model="photos"
          group="photos"
          @start="drag = true"
          @end="drag = false"
        >
          <v-fade-transition
            group
            tag="v-list"
            class="horizontal"
            hide-on-leave
          >
            <thumbnail-with-delete
              class="ma-2"
              v-for="(photo, index) in photos"
              :order="index"
              :key="photo.uuid"
              :uuid="photo.uuid"
              :temporary="photo.temporary"
              @delete="deletePhoto"
            ></thumbnail-with-delete
          ></v-fade-transition>
        </draggable>
        <v-list>
          <upload-image-button
            class="ma-2"
            :multiple="multiple"
            @upload="addPhoto"
          ></upload-image-button
        ></v-list>
      </v-row>
    </v-container>
    <v-input
      :rules="required ? requiredRule : []"
      v-show="required && (!photos || photos.length === 0)"
    >
    </v-input>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, VModel } from 'vue-property-decorator';
import { IPhoto } from '@/interfaces';

@Component
export default class UploadImageSheet extends Vue {
  @VModel() photos: IPhoto[] | undefined;

  @Prop({ default: false }) public multiple!: boolean;
  @Prop({ default: true }) public required!: boolean;
  @Prop({ type: Array, default: () => [] }) public rules!: [];

  public mounted() {
    // console.log(this.photos);
  }

  drag = true;

  public requiredRule = [
    (v = this.getPhotos()) => {
      if (typeof v !== 'object') {
        return v !== undefined || 'Please fill in value';
      } else {
        for (const key in v) {
          if (v[key]) {
            return true;
          }
        }
        return 'Please select at least one photo';
      }
    },
  ];

  getPhotos() {
    return this.photos;
  }

  addPhoto(uuids: string[]) {
    for (const uuid of uuids) {
      const photo = {} as IPhoto;
      photo.uuid = uuid;
      photo.temporary = true;
      if (!this.photos) {
        this.photos = [];
      }
      this.photos.push(photo);
    }
    return this.photos;
  }

  deletePhoto(order: number) {
    if (this.photos) {
      this.photos.splice(order, 1);
    }
  }
}
</script>

<style scoped>
.horizontal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}
</style>
