<template>
  <div>
    <v-card flat outlined>
      <v-container>
        <v-row class="ma-2">
          <v-col cols="12">
            <v-row class="font-weight-bold">Câu hỏi {{ questionId + 1 }}</v-row>
            <v-row>
              <v-text-field
                label="Bạn thích kích cỡ nào?"
                v-model="question.content"
                :rules="questionNameRules"
                single-line
                required
              ></v-text-field
            ></v-row>
          </v-col>
        </v-row>
        <v-row class="ma-2">
          <v-col cols="12">
            <v-row>
              <v-select
                :items="questionTypes"
                outlined
                v-model="select"
                @change="onQuestionTypeChange"
              ></v-select>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="ma-2" v-show="select === EQuestionType.Multi">
          <v-col cols="12">
            <v-row>
              <v-select
                label="Số lần lựa chọn cho phép"
                :items="eAllowedChoiceSelectionItems"
                outlined
                v-model="select2"
              ></v-select>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="ma-2" v-if="select === EQuestionType.Multi">
          <v-col cols="12">
            <v-row class="font-weight-bold">
              <choice-input v-model="choices"></choice-input>
            </v-row>
            <v-row> </v-row>
          </v-col>
        </v-row>

        <v-row class="ma-2">
          <v-col cols="12">
            <v-row align="start">
              <v-col cols="12" align-self="start"
                ><v-row align="start">
                  <v-switch
                    class="ma-0 pa-0"
                    v-model="question.answer_required"
                    color="primary"
                    label="Bắt buộc chọn đáp án"
                  ></v-switch></v-row
              ></v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="ma-2">
          <v-col cols="12">
            <v-row align="start">
              <v-col cols="12">
                <v-row class="font-weight-bold"
                  ><v-btn color="red" outlined @click="deleteMe">
                    <v-icon>{{ svgPath.mdiDelete }}</v-icon>
                    Xoá bỏ</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model } from 'vue-property-decorator';
import {
  IChoice,
  IQuestion,
  EAllowedChoiceSelection,
  EQuestionType,
} from '@/interfaces';
import { mdiImage, mdiDelete } from '@mdi/js';
@Component
export default class QuestionSheet extends Vue {
  @Prop(String) public color: string | undefined;
  @Prop({ default: false }) public multiple!: boolean;
  @Prop(Number) public questionId!: number;
  @Model() public question!: IQuestion;

  questionDescription: string | null = null;
  enableDescription: boolean | null = null;
  choices: IChoice[] | undefined = [];
  moneyPlaceholder = '10.000';
  moneyClearable = true;
  svgPath = {
    mdiImage,
    mdiDelete,
  };

  select2 = EAllowedChoiceSelection.One;
  select = EQuestionType.Text;
  EQuestionType = EQuestionType;

  eAllowedChoiceSelectionItems: any[] = [];
  questionTypes: any[] = [];
  mounted() {
    this.eAllowedChoiceSelectionItems = Object.values(EAllowedChoiceSelection);
    this.questionTypes = Object.values(EQuestionType);
    this.initChoices();
  }

  questionNameRules = [
    (v) => !!v || 'Câu hỏi là bắt buộc',
    (v) => (v && v.length <= 50) || 'Câu hỏi phải ít hơn 50 ký tự',
  ];

  descriptionRules = [
    (v) => !!v || 'Mô tả là bắt buộc',
    (v) => (v && v.length <= 500) || 'Mô tả phải ít hơn 500 ký tự',
  ];

  public priceRules = [
    (v) => !!v || 'Giá là bắt buộc',
    (v) => {
      const price = Number(v.replace(/[.]/g, ''));
      if (price < 999999999) {
        return true;
      } else {
        return 'Giá phải nhỏ hơn 999.999.999 ₫';
      }
    },
  ];

  public moneyOptions = {
    locale: 'vi-VN',
    suffix: '₫',
    precision: 0,
  };

  public questionPriceProperties = {
    hint: 'chỉ điền số',
    type: 'tel',
  };

  initChoices() {
    if (
      this.question.multiple_choice === true &&
      (!this.question.choices || this.question.choices.length === 0)
    ) {
      this.question.choices = [
        {
          content: '',
          surcharge: 0,
        },
      ];
      this.choices = this.question.choices;
    }
  }

  reset() {
    this.question.choices = [];
  }

  deleteMe() {
    this.$emit('delete', this.questionId);
  }

  onQuestionTypeChange(value: string) {
    this.reset();
    if (value === EQuestionType.Text) {
      this.question.multiple_choice = false;
    } else {
      this.question.multiple_choice = true;
      this.initChoices();
    }
  }
}
</script>
