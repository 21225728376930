import Vue from 'vue';
import Router from 'vue-router';

import RouterComponent from './components/RouterComponent.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ x: 0, y: 0, behavior: 'smooth' }),
  routes: [
    {
      path: '/',
      component: () =>
        import(/* webpackChunkName: "start" */ './views/main/Start.vue'),
      children: [
        {
          path: 'landing',
          name: 'Landing',
          component: () =>
            import(/* webpackChunkName: "landing" */ './views/Landing.vue'),
        },
        {
          path: 'create',
          name: 'Create',
          component: () =>
            import(/* webpackChunkName: "create" */ './views/main/Create.vue'),
        },
        {
          path: 'reminder',
          component: () =>
            import(
              /* webpackChunkName: "reminder" */ './views/main/Reminder.vue'
            ),
        },
        {
          path: 'login',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
          path: 'recover-password',
          component: () =>
            import(
              /* webpackChunkName: "recover-password" */ './views/PasswordRecovery.vue'
            ),
        },
        {
          path: 'reset-password',
          component: () =>
            import(
              /* webpackChunkName: "reset-password" */ './views/ResetPassword.vue'
            ),
        },
        {
          path: 'signup',
          component: () =>
            import(
              /* webpackChunkName: "account-activation" */ './views/AccountActivation.vue'
            ),
        },
        {
          path: 'verification-success',
          component: () =>
            import(
              /* webpackChunkName: "verififcation-success" */ './views/VerificationSuccess.vue'
            ),
        },
        {
          path: 'privacy',
          name: 'Privacy',
          component: () =>
            import(
              /* webpackChunkName: "privacy" */ './views/home/Privacy.vue'
            ),
        },
        {
          path: 'terms',
          name: 'Terms',
          component: () =>
            import(/* webpackChunkName: "terms" */ './views/home/Term.vue'),
        },
        {
          path: 'pricing',
          name: 'Pricing',
          component: () =>
            import(
              /* webpackChunkName: "pricing" */ './views/home/Pricing.vue'
            ),
        },
        {
          path: 'main',
          component: () =>
            import(/* webpackChunkName: "main" */ './views/main/Main.vue'),
          children: [
            {
              path: 'orders',
              component: () =>
                import(
                  /* webpackChunkName: "order-list" */ './views/main/order/OrderList.vue'
                ),
            },
            {
              path: 'orders',
              component: RouterComponent,
              redirect: 'order/view',
              children: [
                {
                  path: 'view',
                  name: 'Orders',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile" */ './views/main/order/OrderList.vue'
                    ),
                },
                {
                  path: 'create',
                  name: 'Create Order',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile-edit" */ './views/main/order/OrderCreate.vue'
                    ),
                },
                {
                  path: 'edit/:id',
                  name: 'Edit Order',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile-password" */ './views/main/order/OrderEdit.vue'
                    ),
                },
              ],
            },
            {
              path: 'summary',
              name: 'Summary',
              component: () =>
                import(
                  /* webpackChunkName: "order-summary" */ './views/main/order/OrderSummary.vue'
                ),
            },
            {
              path: 'products',
              name: 'Products',
              component: () =>
                import(
                  /* webpackChunkName: "product-list" */ './views/main/product/ProductList.vue'
                ),
            },
            {
              path: 'products/create',
              name: 'Create Product',
              component: () =>
                import(
                  /* webpackChunkName: "product-create" */ './views/main/product/ProductCreate.vue'
                ),
            },
            {
              path: 'products/edit/:id',
              name: 'Edit Product',
              component: () =>
                import(
                  /* webpackChunkName: "product-create" */ './views/main/product/ProductEdit.vue'
                ),
            },
            {
              path: 'categories',
              name: 'Categories',
              component: () =>
                import(
                  /* webpackChunkName: "product-category" */ './views/main/category/CategoryList.vue'
                ),
            },
            {
              path: 'categories/create',
              name: 'Create Category',
              component: () =>
                import(
                  /* webpackChunkName: "category-create" */ './views/main/category/CategoryCreate.vue'
                ),
            },
            {
              path: 'categories/edit/:id',
              name: 'Edit Category',
              component: () =>
                import(
                  /* webpackChunkName: "category-edit" */ './views/main/category/CategoryEdit.vue'
                ),
            },
            {
              path: 'bundles',
              name: 'Bundles',
              component: () =>
                import(
                  /* webpackChunkName: "bundle-list" */ './views/main/bundle/BundleList.vue'
                ),
            },
            {
              path: 'bundles/create',
              name: 'Create Bundle',
              component: () =>
                import(
                  /* webpackChunkName: "bundle-create" */ './views/main/bundle/BundleCreate.vue'
                ),
            },
            {
              path: 'bundles/edit/:id',
              name: 'Edit Bundle',
              component: () =>
                import(
                  /* webpackChunkName: "bundle-edit" */ './views/main/bundle/BundleEdit.vue'
                ),
            },
            {
              path: 'calendar',
              name: 'Calendar',
              component: () =>
                import(
                  /* webpackChunkName: "calendar" */ './views/main/calendar/Calendar.vue'
                ),
            },
            {
              path: 'promotions',
              name: 'Promotions',
              component: () =>
                import(
                  /* webpackChunkName: "promotion" */ './views/main/promotion/PromotionList.vue'
                ),
            },
            {
              path: 'promotions/create',
              name: 'Create Promotion',
              component: () =>
                import(
                  /* webpackChunkName: "promotion-create" */ './views/main/promotion/PromotionCreate.vue'
                ),
            },
            {
              path: 'analytics',
              name: 'Analytics',
              component: () =>
                import(
                  /* webpackChunkName: "analytic" */ './views/main/analytic/Analytic.vue'
                ),
            },
            {
              path: 'settings',
              name: 'Settings',
              component: () =>
                import(
                  /* webpackChunkName: "setting" */ './views/main/setting/SettingGeneral.vue'
                ),
            },
            {
              path: 'fulfillment',
              name: 'Fulfillment',
              component: () =>
                import(
                  /* webpackChunkName: "fulfillment" */ './views/main/setting/SettingFulfillment.vue'
                ),
            },
            {
              path: 'apperance',
              name: 'Apperance',
              component: () =>
                import(
                  /* webpackChunkName: "apperance" */ './views/main/setting/SettingApperance.vue'
                ),
            },
            {
              path: 'account',
              name: 'Account',
              component: () =>
                import(
                  /* webpackChunkName: "account" */ './views/main/setting/SettingAccount.vue'
                ),
            },
            {
              path: 'payment',
              name: 'Payment',
              component: () =>
                import(
                  /* webpackChunkName: "payment" */ './views/main/setting/SettingPayment.vue'
                ),
            },
            {
              path: 'subscribe',
              name: 'Subscribe',
              component: () =>
                import(
                  /* webpackChunkName: "subscribe" */ './views/main/premium/Membership.vue'
                ),
            },
            {
              path: 'subscribe-result',
              name: 'Subscribe Result',
              component: () =>
                import(
                  /* webpackChunkName: "subscribe-result" */ './views/main/premium/SubscriptionResult.vue'
                ),
            },
            {
              path: 'addons',
              name: 'Addons',
              component: () =>
                import(
                  /* webpackChunkName: "addons" */ './views/main/premium/Addons.vue'
                ),
            },
            {
              path: 'invite',
              name: 'Invite',
              component: () =>
                import(
                  /* webpackChunkName: "invite" */ './views/main/referral/InviteAndEarn.vue'
                ),
            },
            {
              path: 'share',
              name: 'Share',
              component: () =>
                import(
                  /* webpackChunkName: "share" */ './views/main/referral/Share.vue'
                ),
            },
            {
              path: 'profile',
              name: 'Profile',
              component: RouterComponent,
              redirect: 'profile/view',
              children: [
                {
                  path: 'view',
                  name: 'View Profile',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile" */ './views/main/profile/UserProfile.vue'
                    ),
                },
                {
                  path: 'edit',
                  name: 'Edit Profile',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile-edit" */ './views/main/profile/UserProfileEdit.vue'
                    ),
                },
                {
                  path: 'password',
                  name: 'Change Password',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile-password" */ './views/main/profile/UserProfileEditPassword.vue'
                    ),
                },
              ],
            },
            {
              path: 'admin',
              component: () =>
                import(
                  /* webpackChunkName: "main-admin" */ './views/main/admin/Admin.vue'
                ),
              redirect: 'admin/users/all',
              children: [
                {
                  path: 'users',
                  redirect: 'users/all',
                },
                {
                  path: 'users/all',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users" */ './views/main/admin/AdminUsers.vue'
                    ),
                },
                {
                  path: 'users/edit/:id',
                  name: 'main-admin-users-edit',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users-edit" */ './views/main/admin/EditUser.vue'
                    ),
                },
                {
                  path: 'users/create',
                  name: 'main-admin-users-create',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users-create" */ './views/main/admin/CreateUser.vue'
                    ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/*',
      redirect: '/',
    },
  ],
});
