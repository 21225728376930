import { render, staticRenderFns } from "./UploadImageButton.vue?vue&type=template&id=3ca04197&scoped=true"
import script from "./UploadImageButton.vue?vue&type=script&lang=ts"
export * from "./UploadImageButton.vue?vue&type=script&lang=ts"
import style0 from "./UploadImageButton.vue?vue&type=style&index=0&id=3ca04197&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ca04197",
  null
  
)

export default component.exports