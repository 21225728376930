<template>
  <v-dialog v-model="dialog" scrollable max-width="900">
    <template v-slot:activator="{ on, attrs }">
      <v-card outlined v-bind="attrs" v-on="on" class="mx-4"
        ><v-btn plain height="84" color="grey darken-1"
          ><v-icon>{{ svgPath.mdiImageMultiple }}</v-icon
          >Thư viện</v-btn
        ></v-card
      >
    </template>

    <v-card>
      <v-card-text max-height="900">
        <template>
          <v-container class="pa-4 text-center">
            <v-row class="fill-height" align="center" justify="center">
              <template v-for="n in 60">
                <v-col :key="n" cols="12" sm="4" md="3">
                  <v-hover v-slot="{ hover }">
                    <v-card outlined>
                      <v-img
                        :src="require(`@/assets/custom-logo/${n}.png`)"
                        aspect-ratio="1"
                        contain
                        ><v-row style="height: 120%">
                          <v-col
                            align-self="center"
                            self-align="center"
                            fill-height
                          >
                            <v-btn v-show="hover"> Chọn </v-btn>
                          </v-col></v-row
                        >
                      </v-img>
                    </v-card>
                  </v-hover>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </template>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false"> Đóng </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { mdiImageMultiple } from '@mdi/js';

@Component
export default class LogoGalleryDialog extends Vue {
  dialog: boolean = false;

  svgPath = {
    mdiImageMultiple,
  };
}
</script>
