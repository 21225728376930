import { mainApi, adminApi } from '@/api';
import router from '@/router';
import { getLocalToken, removeLocalToken, saveLocalToken } from '@/utils';
import { AxiosError, AxiosResponse } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { AppNotification, MainState } from '@/store/main/state';
import { State } from '@/store/state';
import { IShopCreate, IUserProfileUpdate } from '@/interfaces';
import {
  commitAddNotification,
  commitRemoveNotification,
  commitSetLoggedIn,
  commitSetLogInError,
  commitSetUserProfile,
  commitSetShop,
  commitSetToken,
} from '@/store/main/mutations';

type MainContext = ActionContext<MainState, State>;

export const actions = {
  async actionGetUserProfile(context: MainContext) {
    try {
      const response = await adminApi.getMe(context.state.token);
      if (response.data) {
        commitSetUserProfile(context, response.data);
      }
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionLogIn(
    context: MainContext,
    payload: { username: string; password: string }
  ) {
    try {
      const response = await mainApi.logInGetToken(
        payload.username,
        payload.password
      );
      const token = response.data.access_token;
      if (token) {
        saveLocalToken(token);
        commitSetToken(context, token);
        commitSetLoggedIn(context, true);
        commitSetLogInError(context, false);
        await dispatchGetUserProfile(context);
        await dispatchRouteLoggedIn(context);
        commitAddNotification(context, {
          content: 'Đã đăng nhập',
          color: 'success',
        });
      } else {
        await dispatchLogOut(context);
      }
    } catch (err) {
      commitSetLogInError(context, true);
      await dispatchLogOut(context);
    }
  },
  async actionUpdateUserProfile(context: MainContext, payload) {
    try {
      const loadingNotification = { content: 'đang lưu', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          adminApi.updateMe(context.state.token, payload),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitSetUserProfile(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Cập nhật thông tin thành công',
        color: 'success',
      });
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCheckLoggedIn(context: MainContext) {
    if (!context.state.isLoggedIn) {
      let token = context.state.token;
      if (!token) {
        const localToken = getLocalToken();
        if (localToken) {
          commitSetToken(context, localToken);
          token = localToken;
        }
      }
      if (token) {
        try {
          const response = await adminApi.getMe(token);
          commitSetLoggedIn(context, true);
          commitSetUserProfile(context, response.data);
        } catch (error: any) {
          await dispatchRemoveLogIn(context);
        }
      } else {
        await dispatchRemoveLogIn(context);
      }
    }
  },
  async actionLogInSilently(
    context: MainContext,
    payload: { username: string; password: string }
  ) {
    try {
      const response = await mainApi.logInGetToken(
        payload.username,
        payload.password
      );
      const token = response.data.access_token;
      if (token) {
        saveLocalToken(token);
        commitSetToken(context, token);
        commitSetLoggedIn(context, true);
        commitSetLogInError(context, false);
        await dispatchGetUserProfile(context);
      } else {
        commitSetLogInError(context, true);
      }
    } catch (err) {
      commitSetLogInError(context, true);
    }
  },

  async actionRemoveLogIn(context: MainContext) {
    removeLocalToken();
    commitSetToken(context, '');
    commitSetLoggedIn(context, false);
  },
  async actionLogOut(context: MainContext) {
    await dispatchRemoveLogIn(context);
    await dispatchRouteLogOut(context);
  },
  async actionUserLogOut(context: MainContext) {
    await dispatchLogOut(context);
    commitAddNotification(context, {
      content: 'Đã đăng xuất',
      color: 'success',
    });
  },
  actionRouteLogOut(context: MainContext) {
    if (router.currentRoute.path !== '/login') {
      router.push('/login');
    }
  },
  async actionCheckApiError(context: MainContext, payload: AxiosError) {
    if (payload.response!.status === 401) {
      await dispatchLogOut(context);
    } else if (payload.response!.status === 500) {
      commitAddNotification(context, {
        color: 'error',
        content: 'Lỗi hệ thống Backend',
      });
    } else if (
      payload.response!.status === 422 ||
      payload.response!.status === 400
    ) {
      const errorMsg = payload.response!.data;
      let errorMsgContent = errorMsg;
      if (errorMsg.detail) {
        errorMsgContent = errorMsg.detail;
      }
      commitAddNotification(context, {
        color: 'error',
        content: errorMsgContent,
      });
      throw new Error(errorMsgContent);
    }
  },
  async actionCheckEmailAvailablity(
    context: MainContext,
    payload: { email: string }
  ) {
    const response = (
      await Promise.all([
        mainApi.checkEmailAvailability(payload.email),
        await new Promise<void>((resolve, reject) =>
          setTimeout(() => resolve(), 500)
        ),
      ])
    )[0];
    return response.data;
  },

  async actionCheckDomainAvailablity(
    context: MainContext,
    payload: { subDomainName: string }
  ) {
    const response = (
      await Promise.all([
        mainApi.checkDomainlAvailability(payload.subDomainName),
        await new Promise<void>((resolve, reject) =>
          setTimeout(() => resolve(), 500)
        ),
      ])
    )[0];
    return response.data;
  },

  actionRouteLoggedIn(context: MainContext) {
    if (
      router.currentRoute.path === '/login' ||
      router.currentRoute.path === '/'
    ) {
      router.push('/main').catch((error) => error);
    }
  },
  actionRouteVerificationSuccess(context: MainContext) {
    router.push('/verification-success').catch((error) => error);
  },
  async removeNotification(
    context: MainContext,
    payload: { notification: AppNotification; timeout: number }
  ) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        commitRemoveNotification(context, payload.notification);
        resolve(true);
      }, payload.timeout);
    });
  },
  async passwordRecovery(context: MainContext, payload: { username: string }) {
    const loadingNotification = {
      content: 'Đang gửi email khôi phục mật khẩu',
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          mainApi.passwordRecovery(payload.username),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Email khôi phục mật khẩu đã được gửi',
        color: 'success',
      });
      await dispatchLogOut(context);
    } catch (error: any) {
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        color: 'error',
        content: 'Tên đăng nhập không đúng',
      });
    }
  },
  async resetPassword(
    context: MainContext,
    payload: { password: string; token: string }
  ) {
    const loadingNotification = {
      content: 'Đang đặt lại mật khẩu',
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          mainApi.resetPassword(payload.password, payload.token),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Mật khẩu đã được đặt lại thành công',
        color: 'success',
      });
      await dispatchLogOut(context);
    } catch (error: any) {
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        color: 'error',
        content: 'Lỗi khi đặt lại mật khẩu',
      });
    }
  },
  async actionCreateShop(context: MainContext, payload: IShopCreate) {
    try {
      const loadingNotification = { content: 'đang lưu', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          mainApi.createShop(payload),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 10)
          ),
        ])
      )[0];

      const token = response.data.access_token;
      if (token) {
        commitSetShop(context, response.data);
        commitRemoveNotification(context, loadingNotification);
        commitAddNotification(context, {
          content: 'Shop được khởi tạo thành công',
          color: 'success',
        });
        saveLocalToken(token);
        commitSetToken(context, token);
        commitSetLoggedIn(context, true);
        commitSetLogInError(context, false);
        await dispatchGetUserProfile(context);
        await dispatchRouteLoggedIn(context);
      } else {
        await dispatchRemoveLogIn(context);
      }
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUploadPhotos(context: MainContext, payload: FormData) {
    const loadingNotification = { content: 'đang lưu', showProgress: true };
    try {
      // commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          mainApi.uploadPhotos(payload),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 50)
          ),
        ])
      )[0];
      // commitSetSite(context, response.data);
      // commitRemoveNotification(context, loadingNotification);
      // commitAddNotification(context, {
      //   content: 'Photo successfully uploaded',
      //   color: 'success',
      // });
      return response.data;
    } catch (error: any) {
      // commitRemoveNotification(context, loadingNotification);
      await dispatchCheckApiError(context, error);
    }
  },

  async actionGetDirectUploadUrl(context: MainContext) {
    const response = await mainApi.getCloudflareUploadUrl();
    return response.data;
  },

  async actionUploadPhotoToCloudflare(
    context: MainContext,
    payload: { formData: FormData; url: string | null }
  ) {
    let response: AxiosResponse<any, any>;
    try {
      if (payload.url === null || payload.url === '') {
        response = await mainApi.uploadPhotos(payload.formData);
      } else {
        response = await mainApi.uploadUsingDirectCreator(
          payload.formData,
          payload.url
        );
      }
      return response.data;
    } catch (error: any) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionDeleteCloudflareImage(context: MainContext, imageId: string) {
    const response = await mainApi.deleteCloudflareImage(imageId);
    return response.data;
  },

  async actionActivateAccount(
    context: MainContext,
    payload: IUserProfileUpdate
  ) {
    const loadingNotification = {
      content: 'Verifying account',
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      const response = await mainApi.activateAccount(payload);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Xác minh tài khoản thành công',
        color: 'success',
      });
      await dispatchLoginSilently(context, {
        username: payload.email!,
        password: payload.password!,
      });
      await dispatchRouteVerificationSuccess(context);
    } catch (error: any) {
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        color: 'error',
        content: 'Lỗi khi xác minh tài khoản',
      });
    }
  },
};

const { dispatch } = getStoreAccessors<MainState | any, State>('');

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile);
export const dispatchLogIn = dispatch(actions.actionLogIn);
export const dispatchLoginSilently = dispatch(actions.actionLogInSilently);
export const dispatchLogOut = dispatch(actions.actionLogOut);
export const dispatchUserLogOut = dispatch(actions.actionUserLogOut);
export const dispatchRemoveLogIn = dispatch(actions.actionRemoveLogIn);
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn);
export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut);
export const dispatchRouteVerificationSuccess = dispatch(
  actions.actionRouteVerificationSuccess
);
export const dispatchUpdateUserProfile = dispatch(
  actions.actionUpdateUserProfile
);
export const dispatchRemoveNotification = dispatch(actions.removeNotification);
export const dispatchPasswordRecovery = dispatch(actions.passwordRecovery);
export const dispatchResetPassword = dispatch(actions.resetPassword);
export const dispatchCreateShop = dispatch(actions.actionCreateShop);
export const dispatchCheckEmailAvailablity = dispatch(
  actions.actionCheckEmailAvailablity
);
export const dispatchCheckDomainAvailablity = dispatch(
  actions.actionCheckDomainAvailablity
);
export const dispatchUploadPhotos = dispatch(actions.actionUploadPhotos);
export const dispatchGetDirectUploadUrl = dispatch(
  actions.actionGetDirectUploadUrl
);
export const dispatchUploadPhotoToCloudflare = dispatch(
  actions.actionUploadPhotoToCloudflare
);
export const dispatchDeletePhoto = dispatch(
  actions.actionDeleteCloudflareImage
);
export const dispatchActivateAccount = dispatch(actions.actionActivateAccount);
