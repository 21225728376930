import { en } from 'vuetify/src/locale';

export default {
  ...en,
  bundles: 'Bundles',
  bundle: 'Bundle',
  create: 'Create',
  categories: 'Categories',
  category: 'Category',
  completed: 'Completed',
  order: 'Order',
  orders: 'Orders',
  product: 'Product',
  promotion: 'Promotion',

  create_shop_page: {
    order_our: 'Order our',
    welcome_to: 'Welcome to',
  },

  main: {
    create: 'key 3 internationalization',
  },
};
