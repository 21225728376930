import Vue from 'vue';

const phoneNoFilter = Vue.filter('phoneNo', (value: string) => {
  // Filter only numbers from the input
  const cleaned = ('' + value).replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return match[1] + ' ' + match[2] + ' ' + match[3];
  }

  return value;
});

export default phoneNoFilter;
