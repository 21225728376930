import { IUserProfile, IShop } from '@/interfaces';
import { MainState, AppNotification } from '@/store/main/state';
import { State } from '@/store/state';
import { getStoreAccessors } from 'typesafe-vuex';

export const mutations = {
  setToken(state: MainState, payload: string) {
    state.token = payload;
  },

  setLogInError(state: MainState, payload: boolean) {
    state.logInError = payload;
  },
  setLoggedIn(state: MainState, payload: boolean) {
    state.isLoggedIn = payload;
  },
  setUserProfile(state: MainState, payload: IUserProfile) {
    state.userProfile = payload;
  },
  setDashboardMiniDrawer(state: MainState, payload: boolean) {
    state.dashboardMiniDrawer = payload;
  },
  setDashboardShowDrawer(state: MainState, payload: boolean) {
    state.dashboardShowDrawer = payload;
  },
  addNotification(state: MainState, payload: AppNotification) {
    state.notifications.push(payload);
  },
  removeNotification(state: MainState, payload: AppNotification) {
    state.notifications = state.notifications.filter(
      (notification) => notification !== payload
    );
  },
  setShop(state: MainState, payload: IShop) {
    state.shop = payload;
  },
};

const { commit } = getStoreAccessors<MainState | any, State>('');

export const commitSetDashboardMiniDrawer = commit(
  mutations.setDashboardMiniDrawer
);
export const commitSetDashboardShowDrawer = commit(
  mutations.setDashboardShowDrawer
);

export const commitSetToken = commit(mutations.setToken);
export const commitSetLogInError = commit(mutations.setLogInError);
export const commitSetLoggedIn = commit(mutations.setLoggedIn);
export const commitSetUserProfile = commit(mutations.setUserProfile);
export const commitAddNotification = commit(mutations.addNotification);
export const commitRemoveNotification = commit(mutations.removeNotification);
export const commitSetShop = commit(mutations.setShop);
