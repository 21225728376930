<template>
  <div>
    <v-container>
      <template v-for="(choice, index) in choices">
        <v-row :key="index">
          <v-col cols="8"><v-text-field label="Lựa chọn"></v-text-field></v-col>
          <v-col cols="4">
            <vuetify-money
              v-model="choice.surcharge"
              v-bind:options="moneyOptions"
              v-bind:properties="surchargeProperties"
              :rules="priceRules"
              v-bind:placeholder="moneyPlaceholder"
              @input="onSurchargeChange"
              label="Phụ thu"
            />
          </v-col>
        </v-row>
      </template>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit, VModel } from 'vue-property-decorator';
import { IChoice } from '@/interfaces';

@Component
export default class ChoiceInput extends Vue {
  @VModel() choices: IChoice[] | undefined;
  moneyPlaceholder = '0';
  public moneyOptions = {
    locale: 'vi-VN',
    suffix: '₫',
    precision: 0,
  };

  mounted() {
    if (this.choices === undefined) {
      this.choices = [];
    }
  }

  onSurchargeChange(newValue) {
    if (newValue && newValue > 0 && this.isAllChoicesFilled()) {
      this.addChoice();
    }
  }

  addChoice() {
    const choice: IChoice = {
      surcharge: 0,
      content: '',
    };
    this.choices!.push(choice);
  }

  isAllChoicesFilled() {
    return this.choices!.every((choice) => {
      return choice.surcharge && choice.surcharge > 0;
    });
  }

  public priceRules = [
    (v) => !!v || 'Please fill in value',
    (v) => {
      const price = Number(v.replace(/[.]/g, ''));
      if (price < 999999999) {
        return true;
      } else {
        return 'Price must be less than 999.999.999 ₫';
      }
    },
  ];

  public surchargeProperties = {
    hint: 'chỉ điền số',
    type: 'tel',
  };
}
</script>

<style scoped>
.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
</style>
