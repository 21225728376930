<template>
  <div id="confettiDiv">
    <v-container style="background-color: white" fluid>
      <div
        class="js-container animation-container"
        style="top: 0px !important"
      ></div>

      <div
        style="
          text-align: center;
          margin-top: 30px;
          position: fixed;
          width: 100%;
          height: 100%;
          top: 50px;
          left: 0px;
        "
      >
        <div class="checkmark-circle">
          <div class="background" style="background: #00c09d !important"></div>
          <div class="checkmark draw"></div>
        </div>

        <slot></slot>
      </div>
    </v-container>
  </div>
</template>

<style>
@keyframes confetti-slow {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg);
  }
}
@keyframes confetti-medium {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg);
  }
}
@keyframes confetti-fast {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg);
  }
}
.animation-container {
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  border: 1px solid white;
  display: fixed;
  top: 0px;
}

.confetti-container {
  perspective: 700px;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.confetti {
  position: absolute;
  z-index: 1;
  top: -10px;
  border-radius: 0%;
}
.confetti--animation-slow {
  animation: confetti-slow 2.25s linear 1 forwards;
}
.confetti--animation-medium {
  animation: confetti-medium 1.75s linear 1 forwards;
}
.confetti--animation-fast {
  animation: confetti-fast 1.25s linear 1 forwards;
}

/* Checkmark */
.checkmark-circle {
  width: 80px;
  height: 80px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-left: auto;
  margin-right: auto;
}

.checkmark-circle .background {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #00c09d !important;
  position: absolute;
}

.checkmark-circle .checkmark {
  border-radius: 5px;
  box-sizing: unset !important;
}

.checkmark-circle .checkmark.draw:after {
  -webkit-animation-delay: 100ms;
  -moz-animation-delay: 100ms;
  animation-delay: 100ms;
  -webkit-animation-duration: 3s;
  -moz-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-name: checkmark;
  -moz-animation-name: checkmark;
  animation-name: checkmark;
  -webkit-transform: scaleX(-1) rotate(135deg);
  -moz-transform: scaleX(-1) rotate(135deg);
  -ms-transform: scaleX(-1) rotate(135deg);
  -o-transform: scaleX(-1) rotate(135deg);
  transform: scaleX(-1) rotate(135deg);
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.checkmark-circle .checkmark:after {
  opacity: 1;
  height: 30px;
  width: 27.5px;
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
  border-right: 15px solid white;
  border-top: 15px solid white;
  border-radius: 2.5px !important;
  content: '';
  left: 4px;
  top: 40px;
  position: absolute;
}

@-webkit-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 37.5px;
    opacity: 1;
  }
  40% {
    height: 45px;
    width: 37.5px;
    opacity: 1;
  }
  100% {
    height: 45px;
    width: 27.5px;
    opacity: 1;
  }
}
@-moz-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 27.5px;
    opacity: 1;
  }
  40% {
    height: 45px;
    width: 27.5px;
    opacity: 1;
  }
  100% {
    height: 45px;
    width: 27.5px;
    opacity: 1;
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 27.5px;
    opacity: 1;
  }
  40% {
    height: 45px;
    width: 27.5px;
    opacity: 1;
  }
  100% {
    height: 45px;
    width: 27.5px;
    opacity: 1;
  }
}
</style>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class ConfettiSuccess extends Vue {
  async mounted() {
    document.getElementById('confettiDiv')!.style.overflow = 'hidden';

    let stopTiming: number = 820;
    let fallSpeed: number = 12;
    let disappearTiming: number = 1200;
    this.setup(stopTiming, fallSpeed, disappearTiming);

    fallSpeed = 38;
    stopTiming = 1300;
    disappearTiming = 2500;
    this.setup(stopTiming, fallSpeed, disappearTiming);

    fallSpeed = 60;
    stopTiming = 4200;
    disappearTiming = 3500;
    this.setup(stopTiming, fallSpeed, disappearTiming);
  }

  setup(
    stopTiming: number = 1000,
    fallSpeed: number = 60,
    disappearTiming: number = 1000
  ) {
    let confettiful = new Confettiful(document.querySelector('.js-container'));
    confettiful._setupElements();
    confettiful._renderConfetti(stopTiming, fallSpeed, disappearTiming);
  }
}

class Confettiful {
  el: any;
  containerEl: any = null;
  confettiInterval: any;
  constructor(el) {
    this.el = el;
  }

  confettiFrequency = 1;
  confettiColors = ['#EF2964', '#00C09D', '#2D87B0', '#48485E', '#EFFF1D'];
  confettiAnimations = ['slow', 'medium', 'fast'];
}

interface Confettiful {
  _setupElements();
  _renderConfetti(
    stopTiming: number,
    fallSpeed: number,
    disappearTiming: number
  );
}

Confettiful.prototype._setupElements = function () {
  const containerEl = document.createElement('div');
  const elPosition = this.el.style.position;

  if (elPosition !== 'relative' || elPosition !== 'absolute') {
    this.el.style.position = 'relative';
  }

  containerEl.classList.add('confetti-container');

  this.el.appendChild(containerEl);

  this.containerEl = containerEl;
};

Confettiful.prototype._renderConfetti = function (
  stopTiming: number = 1000,
  fallSpeed: number = 10,
  disappearTiming: number = 1000
) {
  this.confettiInterval = setInterval(() => {
    const confettiEl = document.createElement('div');
    const confettiSize = Math.floor(Math.random() * 3) + 7 + 'px';
    const confettiBackground =
      this.confettiColors[
        Math.floor(Math.random() * this.confettiColors.length)
      ];
    const confettiLeft = Math.floor(Math.random() * this.el.offsetWidth) + 'px';
    const confettiAnimation =
      this.confettiAnimations[
        Math.floor(Math.random() * this.confettiAnimations.length)
      ];

    confettiEl.classList.add(
      'confetti',
      'confetti--animation-' + confettiAnimation
    );
    confettiEl.style.left = confettiLeft;
    confettiEl.style.width = confettiSize;
    confettiEl.style.height = confettiSize;
    confettiEl.style.backgroundColor = confettiBackground;

    setTimeout(function () {
      confettiEl.parentNode?.removeChild(confettiEl);
    }, disappearTiming);

    this.containerEl.appendChild(confettiEl);
  }, fallSpeed);

  setTimeout(() => {
    clearInterval(this.confettiInterval);
  }, stopTiming);
};
</script>
