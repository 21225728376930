import Vue from 'vue';
import Vuetify, {
  VApp,
  VSnackbar,
  VProgressCircular,
  VBtn,
  VContainer,
  VRow,
  VCol,
  VImg,
  VIcon,
  VExpansionPanel,
  VExpansionPanels,
  VExpansionPanelHeader,
  VExpansionPanelContent,
  VChip,
  VCard,
  VCardTitle,
  VCardActions,
  VCardText,
  VCardSubtitle,
  VForm,
  VProgressLinear,
  VSpacer,
  VDivider,
  VList,
  VListItem,
  VListItemTitle,
  VListItemSubtitle,
  VTextField,
  VAvatar,
  VOverlay,
  VTextarea,
  VHover,
  Touch,
  Intersect,
  Resize,
  VMain,
  VToolbar,
  VToolbarTitle,
  VNavigationDrawer,
  VListGroup,
  VListItemIcon,
  VListItemContent,
  VListItemAction,
  VCombobox,
  VMenu,
  VDatePicker,
  VDataTable,
  VAppBar,
  VAppBarNavIcon,
  VSwitch,
  VDialog,
  VFooter,
  VAlert,
  VFadeTransition,
  VInput,
  VSelect,
  VBadge,
  VTooltip,
  VRadioGroup,
  VRadio,
  VCheckbox,
  VListItemAvatar,
  VAutocomplete,
  VSpeedDial,
  VCarousel,
  VCarouselItem,
  VSheet,
} from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify, {
  components: {
    VApp,
    VSnackbar,
    VProgressCircular,
    VBtn,
    VContainer,
    VRow,
    VCol,
    VImg,
    VIcon,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelHeader,
    VExpansionPanelContent,
    VChip,
    VCard,
    VCardTitle,
    VCardActions,
    VCardText,
    VCardSubtitle,
    VForm,
    VProgressLinear,
    VSpacer,
    VDivider,
    VList,
    VListItem,
    VListItemTitle,
    VListItemSubtitle,
    VTextField,
    VAvatar,
    VOverlay,
    VTextarea,
    VHover,
    VMain,
    VToolbar,
    VToolbarTitle,
    VNavigationDrawer,
    VListGroup,
    VListItemIcon,
    VListItemContent,
    VListItemAction,
    VCombobox,
    VMenu,
    VDatePicker,
    VDataTable,
    VAppBar,
    VAppBarNavIcon,
    VSwitch,
    VDialog,
    VFooter,
    VAlert,
    VFadeTransition,
    VInput,
    VSelect,
    VBadge,
    VTooltip,
    VRadioGroup,
    VRadio,
    VCheckbox,
    VListItemAvatar,
    VAutocomplete,
    VSpeedDial,
    VCarousel,
    VCarouselItem,
    VSheet,
  },
  directives: {
    Touch,
    Intersect,
    Resize,
  },
});

// Translation provided by Vuetify (typescript)
import vi from '@/i18n/vuetify/vi';
import en from '@/i18n/vuetify/en';

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.indigo.darken2,
        secondary: colors.grey.darken3,
        accent: colors.indigo.accent3,
        error: colors.red.accent3,
        background: colors.indigo.lighten5,
      },
      dark: {
        primary: colors.indigo.lighten2,
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
  },
  lang: {
    locales: { vi, en },
    current: 'vi',
  },
});
