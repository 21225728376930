<template>
  <div>
    <v-card flat outlined>
      <v-container>
        <v-row class="ma-2">
          <v-col cols="12">
            <v-row class="font-weight-bold">Biến thể {{ variantId + 1 }}</v-row>
            <v-row>
              <v-text-field
                label="Loại thường"
                v-model="variant.name"
                :rules="variantNameRules"
                single-line
                required
              ></v-text-field
            ></v-row>
          </v-col>
        </v-row>
        <v-row class="ma-2">
          <v-col cols="12">
            <v-row class="font-weight-bold">Giá</v-row>
            <v-row>
              <vuetify-money
                v-model="variant.unit_price"
                style="width: 100%"
                v-bind:options="moneyOptions"
                v-bind:properties="variantPriceProperties"
                :rules="priceRules"
                v-bind:placeholder="moneyPlaceholder"
                v-bind:clearable="moneyClearable"
              />
            </v-row>
          </v-col>
        </v-row>
        <v-row class="ma-2">
          <v-col cols="12">
            <v-row class="font-weight-bold">
              <v-icon class="mr-2">{{ svgPath.mdiImage }}</v-icon>
              Hình ảnh</v-row
            >
            <v-row>
              <upload-image-sheet
                v-model="variant.images"
                :multiple="true"
                :required="true"
              ></upload-image-sheet>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="ma-2">
          <v-col cols="12">
            <v-row align="start">
              <v-col cols="12">
                <v-row class="font-weight-bold"
                  >Mô tả <v-spacer></v-spacer>
                </v-row>
              </v-col>
              <v-col cols="1" align-self="start"
                ><v-row align="start">
                  <v-switch
                    class="ma-0 pa-0"
                    v-model="variant.enableDescription"
                    color="primary"
                  ></v-switch></v-row
              ></v-col>
            </v-row>

            <v-row v-show="variant.enableDescription">
              <span class="text-body-2"
                >Thêm miêu tả sẽ làm cho hình ảnh đại diện của biến thể nhỏ
                hơn.</span
              >
              <v-textarea
                auto-grow
                v-model="variant.description"
                :rules="enableDescription ? descriptionRules : []"
                rows="1"
              ></v-textarea
            ></v-row>
          </v-col>
        </v-row>

        <v-row class="ma-2">
          <v-col cols="12">
            <v-row align="start">
              <v-col cols="12">
                <v-row class="font-weight-bold"
                  ><v-btn color="red" outlined @click="deleteVariant">
                    <v-icon>{{ svgPath.mdiDelete }}</v-icon>
                    Xoá bỏ</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit, Model } from 'vue-property-decorator';
import { IPhoto, IProductVariant } from '@/interfaces';
import { mdiImage, mdiDelete } from '@mdi/js';
@Component
export default class VariantSheet extends Vue {
  @Prop(String) public color: string | undefined;
  @Prop({ default: false }) public multiple!: boolean;
  @Prop(Number) public variantId!: number;
  @Model() public variant!: IProductVariant;

  enableDescription: boolean | null = null;
  moneyPlaceholder = '10.000';
  moneyClearable = true;
  svgPath = {
    mdiImage,
    mdiDelete,
  };

  mounted() {
    // show variant to console log
  }

  variantNameRules = [
    (v) => !!v || 'Tên biến thể là bắt buộc',
    (v) => (v && v.length <= 50) || 'Tên biến thể phải ít hơn 50 ký tự',
  ];

  descriptionRules = [
    (v) => !!v || 'Mô tả là bắt buộc',
    (v) => (v && v.length <= 500) || 'Mô tả phải ít hơn 500 ký tự',
  ];

  public priceRules = [
    (v) => !!v || 'Giá là bắt buộc',
    (v) => {
      const price = Number(v.replace(/[.]/g, ''));
      if (price < 999999999) {
        return true;
      } else {
        return 'Giá phải nhỏ hơn 1 tỷ ₫';
      }
    },
  ];

  public moneyOptions = {
    locale: 'vi-VN',
    suffix: '₫',
    precision: 0,
  };

  public variantPriceProperties = {
    hint: 'chỉ điền số',
    type: 'tel',
  };

  deleteVariant() {
    this.$emit('delete', this.variantId);
  }
}
</script>

<style scoped>
.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
</style>
