import axios from 'axios';
import { apiAdminUrlV1, apiMainUrlV1 } from '@/env';
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
  IShop,
  IShopCreate,
  IShopUpdate,
  IProductCreate,
  IProductUpdate,
  IBundleCreate,
  IBundleProductCreate,
} from '@/interfaces';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const mainApi = {
  /*************************************************************
   * Main Public API
   *************************************************************/
  // Users API
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${apiMainUrlV1}/login/access-token`, params);
  },

  async checkEmailAvailability(email: string) {
    return axios.get(`${apiMainUrlV1}/users/validate/email`, {
      params: { email },
    });
  },

  async createShop(data: IShopCreate) {
    return axios.post(`${apiMainUrlV1}/shops/`, data);
  },

  // Domains API
  async checkDomainlAvailability(subDomainName: string) {
    return axios.get(`${apiMainUrlV1}/domains/check/availability`, {
      params: { sub_domain_name: subDomainName },
    });
  },

  // Photos API
  async uploadPhotos(data: FormData) {
    return axios.post(`${apiMainUrlV1}/utils/upload/photos`, data);
  },

  // Get Cloudflare images one time upload url
  async getCloudflareUploadUrl() {
    return axios.get(`${apiMainUrlV1}/utils/upload-url`);
  },

  // Password reset API
  async passwordRecovery(email: string) {
    return axios.post(`${apiMainUrlV1}/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiMainUrlV1}/reset-password/`, {
      new_password: password,
      token,
    });
  },

  // Upload to cloudflare images using one time direct upload link
  async uploadUsingDirectCreator(data: FormData, url: string) {
    return axios.post(url, data);
  },

  // Delete from Cloudflare images
  async deleteCloudflareImage(id: string) {
    return axios.delete(`${apiMainUrlV1}/utils/photos/${id}`);
  },
  async activateAccount(data: IUserProfileUpdate) {
    return axios.post(`${apiMainUrlV1}/activate-account`, data);
  },
};
/*************************************************************
 * Admin API
 *************************************************************/
export const adminApi = {
  // Users API
  async getMe(token: string) {
    return axios.get<IUserProfile>(
      `${apiAdminUrlV1}/users/me`,
      authHeaders(token)
    );
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(
      `${apiAdminUrlV1}/users/me`,
      data,
      authHeaders(token)
    );
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(
      `${apiAdminUrlV1}/users/`,
      authHeaders(token)
    );
  },
  async updateUser(token: string, userId: string, data: IUserProfileUpdate) {
    return axios.put(
      `${apiAdminUrlV1}/users/${userId}`,
      data,
      authHeaders(token)
    );
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiAdminUrlV1}/users/`, data, authHeaders(token));
  },

  // Shops API
  async getShops(token: string) {
    return axios.get<IShop[]>(`${apiAdminUrlV1}/shops/`, authHeaders(token));
  },
  async updateShop(token: string, shopId: string, data: IShopUpdate) {
    return axios.put(
      `${apiAdminUrlV1}/shops/${shopId}`,
      data,
      authHeaders(token)
    );
  },

  async getDomain(token: string) {
    return axios.get(`${apiAdminUrlV1}/domains/`, authHeaders(token));
  },
  // Products API
  async getProducts(token: string) {
    return axios.get(`${apiAdminUrlV1}/products/`, authHeaders(token));
  },

  // Get Product and Variants as Products
  async getVariantsAsProducts(token: string) {
    return axios.get(`${apiAdminUrlV1}/products/variants/`, authHeaders(token));
  },

  async getProduct(token: string, productId: string) {
    return axios.get(
      `${apiAdminUrlV1}/products/${productId}`,
      authHeaders(token)
    );
  },
  async createProductMeta(
    token: string,
    data: { product: IProductCreate; categoryId: string }
  ) {
    return axios.post(
      `${apiAdminUrlV1}/products/?category_id=${data.categoryId}`,
      data.product,
      authHeaders(token)
    );
  },
  async updateProduct(token: string, productId: string, data: IProductUpdate) {
    return axios.patch(
      `${apiAdminUrlV1}/products/${productId}`,
      data,
      authHeaders(token)
    );
  },
  async updateProductMeta(
    token: string,
    productId: string,
    data: IProductUpdate
  ) {
    return axios.put(
      `${apiAdminUrlV1}/products/${productId}`,
      data,
      authHeaders(token)
    );
  },
  async deleteProduct(token: string, productId: string) {
    return axios.delete(
      `${apiAdminUrlV1}/products/${productId}`,
      authHeaders(token)
    );
  },

  // get order
  async getOrder(token: string, orderId: string) {
    return axios.get(`${apiAdminUrlV1}/orders/${orderId}`, authHeaders(token));
  },

  // get orders
  async getOrders(token: string) {
    return axios.get(`${apiAdminUrlV1}/orders/`, authHeaders(token));
  },
  // update order status
  async updateOrderStatus(token: string, data: any) {
    return axios.put(
      `${apiAdminUrlV1}/orders/status/${data.orderId}`,
      data.order,
      authHeaders(token)
    );
  },
  // update order meta
  async updateOrderMeta(token: string, data: any) {
    return axios.put(
      `${apiAdminUrlV1}/orders/${data.orderId}`,
      data.order,
      authHeaders(token)
    );
  },
  // create order meta
  async createOrderMeta(token: string, data: any) {
    return axios.post(`${apiAdminUrlV1}/orders/`, data, authHeaders(token));
  },
  // get delivery method templates
  async getFulfillmentTemplates(token: string) {
    return axios.get(
      `${apiAdminUrlV1}/fulfillmentTemplates/`,
      authHeaders(token)
    );
  },

  // update delivery method templates
  async updateFulfillmentTemplates(token: string, data: any) {
    return axios.post(
      `${apiAdminUrlV1}/fulfillmentTemplates/multi/`,
      data,
      authHeaders(token)
    );
  },

  // get payment methods
  async getPaymentMethods(token: string) {
    return axios.get(`${apiAdminUrlV1}/paymentMethods/`, authHeaders(token));
  },

  // get categories
  async getCategories(token: string) {
    return axios.get(`${apiAdminUrlV1}/categories/`, authHeaders(token));
  },

  // create category
  async createCategory(
    token: string,
    data: { category_in: any; product_ids: string[] }
  ) {
    return axios.post(`${apiAdminUrlV1}/categories/`, data, authHeaders(token));
  },

  // update category
  async updateCategory(
    token: string,
    data: { category_id: string; category_in: any; product_ids: string[] }
  ) {
    return axios.put(
      `${apiAdminUrlV1}/categories/${data.category_id}`,
      data,
      authHeaders(token)
    );
  },

  // delete category
  async deleteCategory(token: string, categoryId: string) {
    return axios.delete(
      `${apiAdminUrlV1}/categories/${categoryId}`,
      authHeaders(token)
    );
  },

  // create bundle
  async createBundle(
    token: string,
    data: {
      payload: {
        bundle_in: IBundleCreate;
        bundled_products_in: IBundleProductCreate[];
      };
      category_id: string;
    }
  ) {
    return axios.post(
      `${apiAdminUrlV1}/bundles/?category_id=${data.category_id}`,
      data.payload,
      authHeaders(token)
    );
  },

  // update bundle
  async updateBundle(token: string, data: any) {
    return axios.put(
      `${apiAdminUrlV1}/bundles/${data.id}`,
      data,
      authHeaders(token)
    );
  },

  // delete bundle
  async deleteBundle(token: string, bundleId: string) {
    return axios.delete(
      `${apiAdminUrlV1}/bundles/${bundleId}`,
      authHeaders(token)
    );
  },

  // get bundles
  async getBundles(token: string) {
    return axios.get(`${apiAdminUrlV1}/bundles/`, authHeaders(token));
  },

  // get bundle
  async getBundle(token: string, bundleId: string) {
    return axios.get(
      `${apiAdminUrlV1}/bundles/${bundleId}`,
      authHeaders(token)
    );
  },

  // update payment methods
  async updatePaymentMethods(token: string, data: any) {
    return axios.put(
      `${apiAdminUrlV1}/paymentMethods/`,
      data,
      authHeaders(token)
    );
  },

  // get calendars
  async getCalendars(token: string) {
    return axios.get(`${apiAdminUrlV1}/calendars/`, authHeaders(token));
  },

  // get calendar
  async getCalendar(token: string, calendarId: string) {
    return axios.get(
      `${apiAdminUrlV1}/calendars/${calendarId}`,
      authHeaders(token)
    );
  },

  // create calendar
  async createCalendar(token: string, data: any) {
    return axios.post(`${apiAdminUrlV1}/calendars/`, data, authHeaders(token));
  },

  // create calendars
  async createCalendars(token: string, data: any) {
    return axios.post(
      `${apiAdminUrlV1}/calendars/multi/`,
      data,
      authHeaders(token)
    );
  },

  // get shop_settings
  async getShopSetting(token: string) {
    return axios.get(`${apiAdminUrlV1}/shopSettings/`, authHeaders(token));
  },

  // update shop_settings
  async updateShopSetting(token: string, data: any) {
    return axios.put(
      `${apiAdminUrlV1}/shopSettings/`,
      data,
      authHeaders(token)
    );
  },

  // download file as blob
  async downloadFile(url: string) {
    return axios.get(url, {
      responseType: 'blob',
    });
  },

  async downloadAuthorizedFile(token: string, url: string) {
    return axios.get(url, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  // create promotion
  async createPromotion(token: string, data: any) {
    return axios.post(`${apiAdminUrlV1}/promotions/`, data, authHeaders(token));
  },

  // update promotion
  async updatePromotion(token: string, data: any) {
    return axios.put(
      `${apiAdminUrlV1}/promotions/${data.id}`,
      data,
      authHeaders(token)
    );
  },

  // delete promotion
  async deletePromotion(token: string, promotionId: string) {
    return axios.delete(
      `${apiAdminUrlV1}/promotions/${promotionId}`,
      authHeaders(token)
    );
  },

  // get promotions
  async getPromotions(token: string) {
    return axios.get(`${apiAdminUrlV1}/promotions/`, authHeaders(token));
  },

  // get reports summary
  async getReportsSummary(token: string, startDate: string, endDate: string) {
    return axios.get(
      `${apiAdminUrlV1}/reports/summary-report?start_date=${startDate}&end_date=${endDate}`,
      authHeaders(token)
    );
  },

  // get bank list from VietQR
  async getBankList() {
    return axios.get('https://api.vietqr.io/v2/banks');
  },

  // Momo Collection link
  async getPaymentLink(
    token: string,
    requestType: string,
    selectedPlanCode: string
  ) {
    return axios.get(
      `${apiAdminUrlV1}/payments/method?requestType=${requestType}&selectedPlanCode=${selectedPlanCode}`,
      authHeaders(token)
    );
  },

  // get plan list
  async getPlans(token: string) {
    return axios.get(`${apiAdminUrlV1}/plans/`, authHeaders(token));
  },

  // get active subscription
  async getActiveSubscription(token: string) {
    return axios.get(
      `${apiAdminUrlV1}/subscriptions/active`,
      authHeaders(token)
    );
  },

  // get invoices
  async getInvoices(token: string) {
    return axios.get(`${apiAdminUrlV1}/invoices/`, authHeaders(token));
  },

  // refund
  async refund(token: string) {
    return axios.post(
      `${apiAdminUrlV1}/payments/refund`,
      {},
      authHeaders(token)
    );
  },

  // get refund list
  async getRefundInfo(token: string) {
    return axios.get(
      `${apiAdminUrlV1}/payments/refund-info`,
      authHeaders(token)
    );
  },
};
