<template>
  <v-autocomplete
    outlined
    return-object
    placeholder="Chọn ngân hàng"
    item-text="shortName"
    :items="items"
    v-model="selectedBank"
    :rules="requiredRules"
    @input="onInput"
  >
    <template v-slot:selection="slotProps">
      <v-avatar size="42"
        ><v-img :src="slotProps.item.logo" contain> </v-img
      ></v-avatar>
      <span class="px-2">
        {{ slotProps.item.shortName }}
      </span>
    </template>
    <template v-slot:item="slotProps">
      <v-list-item-avatar size="42">
        <v-img :src="slotProps.item.logo" contain> </v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <span>{{ slotProps.item.shortName }}</span>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import {
  Component,
  Vue,
  Prop,
  Model,
  Emit,
  Watch,
} from 'vue-property-decorator';
import {} from '@/interfaces';
import { dispatchGetBankList } from '@/store/admin/actions';
import { mdiImage, mdiDelete } from '@mdi/js';
@Component
export default class BankList extends Vue {
  @Prop(String) public bankCode: string | undefined;
  @Prop({ default: false }) public multiple!: boolean;
  @Prop(Number) public questionId!: number;

  items = [];
  selectedBank = {};

  svgPath = {
    mdiImage,
    mdiDelete,
  };

  requiredRules = [(v: string) => !!v || 'Trường này là bắt buộc'];

  async mounted() {
    this.items = await dispatchGetBankList(this.$store);
    this.selectedBank =
      this.items.find((item: any) => item.code === this.bankCode) || {};
  }

  @Emit('input')
  onInput(value: any) {
    return value;
  }
}
</script>
