<template>
  <v-dialog v-model="dialog" persistent max-width="310">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="secondary lighten-3" dark v-bind="attrs" v-on="on">
        Làm lại
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5"> Bạn muốn thực hiên lại? </v-card-title>
      <v-card-text
        >Những thay đổi trước đó của bạn sẽ không được lưu lại</v-card-text
      >
      <v-card-actions>
        <v-btn color="grey" block text @click="dialog = false">
          Không, tiếp tục chỉnh sửa
        </v-btn>
      </v-card-actions>
      <v-card-actions>
        <v-btn color="primary" block text @click="reset">
          Đúng, huỷ để làm lại
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ResetConfirmationDialog extends Vue {
  dialog: boolean = false;

  reset() {
    this.$router.go(0);
  }
}
</script>
