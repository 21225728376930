<template>
  <div class="movable">
    <v-card outlined width="84" height="84" rounded flat>
      <v-img :src="src" :lazy-src="src" contain height="100%">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <span class="order">{{ order + 1 }}</span>
        <v-btn
          icon
          color="black"
          class="ml-12"
          elevation="2"
          @click="deletePhoto"
        >
          <v-icon>{{ svgPath.mdiCloseCircle }}</v-icon>
        </v-btn>
      </v-img>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { mdiCloseCircle } from '@mdi/js';
import { dispatchDeletePhoto } from '@/store/main/actions';
import { getImageUrl } from '@/utils';
import { IMAGES_VARIANT_THUMBNAIL } from '@/env';
@Component
export default class ThumbnailWithDelete extends Vue {
  @Prop(Number) public order: number | undefined;
  @Prop(String) public uuid: string | undefined;
  @Prop({ default: false }) public temporary!: boolean;
  svgPath = {
    mdiCloseCircle,
  };
  src = '';
  public mounted() {
    this.src = getImageUrl(this.uuid!, IMAGES_VARIANT_THUMBNAIL);
  }

  @Emit('delete')
  public deletePhoto() {
    this.deleteImageFromCF();
    return this.order;
  }

  deleteImageFromCF() {
    if (this.uuid && this.temporary) {
      dispatchDeletePhoto(this.$store, this.uuid);
    }
  }
}
</script>

<style scoped>
.movable {
  cursor: all-scroll;
}

.order {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 1;
  color: #fff;
  text-shadow: 2px 2px 4px #000000;
}
</style>
