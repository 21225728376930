<template>
  <div>
    <v-hover
      ><template v-slot:default="{ hover }"
        ><v-card
          outlined
          align="middle"
          @click="trigger"
          width="84"
          height="84"
          :class="hover ? 'medium-border' : 'primary-border'"
          rounded
          flat
          :loading="loading"
          :disabled="loading"
          ><v-avatar
            class="profile rounded"
            color="white"
            size="82"
            rounded
            v-show="loading || (inline && (thumbnail || existingUrl))"
            ><v-img :src="thumbnail ?? existingUrl" contain> </v-img></v-avatar
          ><v-card-text v-show="!thumbnail && !loading"
            ><v-icon dense size="24" class="mt-3">
              {{ svgPath.mdiPlus }}
            </v-icon>
          </v-card-text></v-card
        >
      </template>
    </v-hover>
    <input
      :multiple="multiple"
      class="visually-hidden"
      type="file"
      accept="image/png, image/gif, image/jpeg"
      v-on:change="files"
      ref="fileInput"
    />
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Emit, Model } from 'vue-property-decorator';
import { mdiPlus } from '@mdi/js';
import { getImageUrl } from '@/utils';
import { IMAGES_VARIANT_AVATAR } from '@/env';
import {
  dispatchUploadPhotoToCloudflare,
  dispatchDeletePhoto,
  dispatchGetDirectUploadUrl,
} from '@/store/main/actions';
@Component
export default class UploadImageButton extends Vue {
  @Prop({ default: false }) public multiple!: boolean;
  @Prop({ default: false }) public inline!: boolean;
  @Prop(String) public uuid!: string | undefined;

  productPhotoIds: string[] | undefined;

  loading: boolean = false;

  svgPath = {
    mdiPlus,
  };

  thumbnail: any = null;

  get existingUrl() {
    return getImageUrl(this.uuid, IMAGES_VARIANT_AVATAR);
  }

  public async files(e) {
    this.loading = true;
    const photoFiles = e.target.files || e.dataTransfer.files;
    const promises: any[] = [];
    for (const file of photoFiles) {
      const p = this.createImage(file, (image: any) => {
        this.thumbnail = image;
      });
      promises.push(p);
    }
    Promise.all(promises).then(() => {
      this.loading = false;
      if (!this.inline && this.thumbnail) {
        this.thumbnail = null;
      }
      this.emit();
    });
  }

  @Emit('upload')
  public emit(): string[] {
    if (this.productPhotoIds) {
      const returnValue = Array.from(this.productPhotoIds);
      this.productPhotoIds = [];
      return returnValue;
    } else {
      return [];
    }
  }

  public trigger() {
    (this.$refs.fileInput as HTMLElement).click();
  }

  async createImage(file, callback) {
    const reader = new FileReader();
    const vm = this;
    reader.onload = (e) => {
      const imageTmp = new Image();
      if (vm && e && e.target) {
        // vm.image = e.target.result;
        // vm.image = this.image;
        imageTmp.src = e.target.result as string;

        callback(e.target.result);
      }
    };

    reader.readAsDataURL(file);
    const photoForm: FormData = new FormData();
    photoForm.append('file', file);
    const uploadUrl = await this.getProductPhotoDirectUploadUrl();
    await this.uploadProductPhoto({
      formData: photoForm,
      url: uploadUrl,
    });
  }

  async getProductPhotoDirectUploadUrl() {
    const uploadUrl = await dispatchGetDirectUploadUrl(this.$store);
    return uploadUrl;
  }

  async uploadProductPhoto(payload: {
    formData: FormData;
    url: string | null;
  }) {
    // clean the previous temporary uploaded photo
    try {
      const resp = await dispatchUploadPhotoToCloudflare(this.$store, payload);
      if (!this.productPhotoIds) {
        this.productPhotoIds = [];
      }
      this.productPhotoIds.push(resp.result.id);
    } catch (e) {
      // console.log(e);
    }
  }
}
</script>

<style scoped>
.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.medium-border {
  outline: solid 2px #3949ab;
  background-color: #e8eaf6 !important;
  transition: all 0.2s ease;
}
</style>
