const env = process.env.VUE_APP_ENV;

let envApiUrl = '';
let envWebsocketUrl = '';
// if (env === 'production') {
//   envApiUrl = `https://${process.env.VUE_APP_DOMAIN_PROD}`;
//   envWebsocketUrl = `wss://${process.env.VUE_APP_DOMAIN_PROD}`;
// } else if (env === 'staging') {
//   envApiUrl = `https://${process.env.VUE_APP_DOMAIN_STAG}`;
//   envWebsocketUrl = `wss://${process.env.VUE_APP_DOMAIN_STAG}`;
// } else {
//   envApiUrl = `http://${process.env.VUE_APP_DOMAIN_DEV}`;
//   envWebsocketUrl = `ws://${process.env.VUE_APP_DOMAIN_DEV}`;
// }

if (env === 'production') {
  envApiUrl = `https://${location.hostname}`;
  envWebsocketUrl = `wss://${location.hostname}`;
} else if (env === 'staging') {
  envApiUrl = `https://${location.hostname}`;
  envWebsocketUrl = `wss://${location.hostname}`;
} else {
  envApiUrl = `http://${location.hostname}`;
  envWebsocketUrl = `ws://${location.hostname}`;
}

export const apiAdminUrlV1 = `${envApiUrl}/api/v1/admin`;
export const apiMainUrlV1 = `${envApiUrl}/api/v1/main`;
export const websocketUrl = envWebsocketUrl;
export const appName = process.env.VUE_APP_NAME;
export const CF_IMAGES_ACC_HASH = 'zXHPVumwuJUE8Wbecl7KWA';
export const IMAGES_VARIANT_PUBLIC = 'public';
export const IMAGES_VARIANT_THUMBNAIL = 'thumbnail';
export const IMAGES_VARIANT_AVATAR = 'avatar';
export const IMAGES_VARIANT_ICON = 'icon';
