import { AdminState } from '@/store/admin/state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '@/store/state';
import { IProduct } from '../../interfaces/index';
import { OrderStatuses } from '@/config';

export const getters = {
  adminUsers: (state: AdminState) => state.users,
  adminOneUser: (state: AdminState) => (userId: string) => {
    const filteredUsers = state.users.filter((user) => user.id === userId);
    if (filteredUsers.length > 0) {
      return { ...filteredUsers[0] };
    }
  },
  adminProducts: (state: AdminState) => state.products,
  adminVariantsAsProducts: (state: AdminState) => {
    const variantsAsProducts: IProduct[] = [];
    // loop through the state.variantProducts, and for each variant, create a new product
    // with the variant's properties
    for (const product of state.products) {
      if (product.variants && product.variants.length > 0) {
        for (const variant of product.variants) {
          const newProduct: IProduct = { ...variant, variants: [] };
          variantsAsProducts.push(newProduct);
        }
      } else {
        variantsAsProducts.push(product);
      }
    }
    return variantsAsProducts;
  },
  adminBundlesAsProducts: (state: AdminState) => {
    const bundlesAsProducts: IProduct[] = [];
    // create a new list that append state.bundles and state.products
    for (const bundle of state.bundles) {
      const newProduct: IProduct = { ...bundle, variants: [] };
      bundlesAsProducts.push(newProduct);
    }
    for (const product of state.products) {
      bundlesAsProducts.push(product);
    }
    return bundlesAsProducts;
  },
  adminOneProduct: (state: AdminState) => (productId: string) => {
    const filteredProducts = state.products.filter(
      (product) => product.id === productId
    );
    if (filteredProducts.length > 0) {
      return { ...filteredProducts[0] };
    }
  },
  adminOrders: (state: AdminState) => {
    // loop orders and modify the created date to be local time instead of UTC
    const orders = state.orders.map((order) => {
      const createdDate = new Date(order.created_at!);
      const localDate = new Date(
        createdDate.getTime() - createdDate.getTimezoneOffset() * 60000
      );
      // if status is null then set to OrderStatuses.UNPAID
      if (!order.status) {
        order.status = OrderStatuses.UNPAID;
      }

      return { ...order, created_at: localDate };
    });
    return orders;
  },
  adminOneOrder: (state: AdminState) => (orderId: string) => {
    const filteredOrders = state.orders.filter((order) => order.id === orderId);
    if (filteredOrders.length > 0) {
      return { ...filteredOrders[0] };
    }
  },
  adminFulfillmentTemplates: (state: AdminState) => state.fulfillmentTemplates,
  adminOneFulfillmentTemplate: (state: AdminState) => (templateId: string) => {
    const filteredTemplates = state.fulfillmentTemplates.filter(
      (template) => template.id === templateId
    );
    if (filteredTemplates.length > 0) {
      return { ...filteredTemplates[0] };
    }
  },
  adminPaymentMethods: (state: AdminState) => state.paymentMethods,
  adminOnePaymentMethod: (state: AdminState) => (paymentMethodId: string) => {
    const filteredPaymentMethods = state.paymentMethods.filter(
      (paymentMethod) => paymentMethod.id === paymentMethodId
    );
    if (filteredPaymentMethods.length > 0) {
      return { ...filteredPaymentMethods[0] };
    }
  },
  adminOnePaymentMethodByType:
    (state: AdminState) => (paymentMethodType: string) => {
      const filteredPaymentMethods = state.paymentMethods.filter(
        (paymentMethod) => paymentMethod.payment_type === paymentMethodType
      );

      if (filteredPaymentMethods.length > 0) {
        return { ...filteredPaymentMethods[0] };
      }
    },
  adminCategories: (state: AdminState) => state.categories,
  adminOneCategory: (state: AdminState) => (categoryId: string) => {
    const filteredCategories = state.categories.filter(
      (category) => category.id === categoryId
    );
    if (filteredCategories.length > 0) {
      return { ...filteredCategories[0] };
    }
  },
  adminBundles: (state: AdminState) => state.bundles,
  adminOneBundle: (state: AdminState) => (bundleId: string) => {
    const filteredBundles = state.bundles.filter(
      (bundle) => bundle.id === bundleId
    );
    if (filteredBundles.length > 0) {
      return { ...filteredBundles[0] };
    }
  },
  adminCalendars: (state: AdminState) => state.calendars,
  adminOneCalendar: (state: AdminState) => (calendarId: string) => {
    const filteredCalendars = state.calendars.filter(
      (calendar) => calendar.id === calendarId
    );
    if (filteredCalendars.length > 0) {
      return { ...filteredCalendars[0] };
    }
  },
  adminShopSetting: (state: AdminState) => state.shopSetting,
  adminPromotions: (state: AdminState) => state.promotions,
  adminOnePromotion: (state: AdminState) => (promotionId: string) => {
    const filteredPromotions = state.promotions.filter(
      (promotion) => promotion.id === promotionId
    );
    if (filteredPromotions.length > 0) {
      return { ...filteredPromotions[0] };
    }
  },
  adminPlans: (state: AdminState) => state.plans,
  adminOnePlan: (state: AdminState) => (planId: string, planCode: string) => {
    if (planCode) {
      const filteredPlans = state.plans.filter(
        (plan) => plan.plan_code === planCode
      );
      if (filteredPlans.length > 0) {
        return { ...filteredPlans[0] };
      }
    } else {
      const filteredPlans = state.plans.filter((plan) => plan.id === planId);
      if (filteredPlans.length > 0) {
        return { ...filteredPlans[0] };
      }
    }
  },
  adminInvoices: (state: AdminState) => {
    // loop invoices  and calculate total amount
    const invoices = state.invoices.map((invoice) => {
      let amount = 0;
      for (const item of invoice.invoice_line_items) {
        amount += item.line_amount ?? 0;
      }
      return { ...invoice, amount };
    });
    return invoices;
  },
  adminOneInvoice: (state: AdminState) => (invoiceId: string) => {
    const filteredInvoices = state.invoices.filter(
      (invoice) => invoice.id === invoiceId
    );
    if (filteredInvoices.length > 0) {
      return { ...filteredInvoices[0] };
    }
  },

  adminOneActiveSubscription: (state: AdminState) => {
    if (state.activeSubscription) {
      return { ...state.activeSubscription };
    }
  },
};

const { read } = getStoreAccessors<AdminState, State>('');

export const readAdminOneUser = read(getters.adminOneUser);
export const readAdminUsers = read(getters.adminUsers);
export const readAdminOneProduct = read(getters.adminOneProduct);
export const readAdminProducts = read(getters.adminProducts);
export const readAdminVariantsAsProducts = read(
  getters.adminVariantsAsProducts
);
export const readAdminOrders = read(getters.adminOrders);
export const readAdminOneOrder = read(getters.adminOneOrder);
export const readFulfillmentTemplates = read(getters.adminFulfillmentTemplates);
export const readAdminOneFulfillmentTemplate = read(
  getters.adminOneFulfillmentTemplate
);
export const readAdminPaymentMethods = read(getters.adminPaymentMethods);
export const readAdminOnePaymentMethod = read(getters.adminOnePaymentMethod);
export const readAdminOnePaymentMethodByType = read(
  getters.adminOnePaymentMethodByType
);
export const readAdminCategories = read(getters.adminCategories);
export const readAdminOneCategory = read(getters.adminOneCategory);
export const readAdminBundles = read(getters.adminBundles);
export const readAdminOneBundle = read(getters.adminOneBundle);
export const readAdminBundlesAsProducts = read(getters.adminBundlesAsProducts);
export const readAdminCalendars = read(getters.adminCalendars);
export const readAdminOneCalendar = read(getters.adminOneCalendar);
export const readAdminShopSetting = read(getters.adminShopSetting);
export const readAdminPromotions = read(getters.adminPromotions);
export const readAdminOnePromotion = read(getters.adminOnePromotion);
export const readAdminPlans = read(getters.adminPlans);
export const readAdminOnePlan = read(getters.adminOnePlan);
export const readAdminOneActiveSubscription = read(
  getters.adminOneActiveSubscription
);
export const readAdminInvoices = read(getters.adminInvoices);
