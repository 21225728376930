import Vue from 'vue';

const vndFilter = Vue.filter('vnd', (value: number) => {
  if (!value || value === 0) {
    return 'Miễn phí';
  } else {
    const val = (value / 1).toFixed(0).replace('.', ',');
    const returnVal = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return `${returnVal} ₫`;
  }
});

export default vndFilter;
