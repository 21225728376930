// Import Component hooks before component definitions
import '@/component-hooks';
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/registerServiceWorker';
import '@/plugins/vuetify-money';
import VueFilterDateFormat from 'vue-filter-date-format';
import VuetifyAsyncValidation from 'vuetify-async-validation';
import UploadImageButton from '@/components/UploadImageButton.vue';
import UploadImageSheet from '@/components/UploadImageSheet.vue';
import ThumbnailWithDelete from '@/components/ThumbnailWithDelete.vue';
import FeatureInProgressBanner from '@/components/FeatureInProgressBanner.vue';
import ResetConfirmationDialog from '@/components/ResetConfirmationDialog.vue';
import VariantSheet from '@/components/VariantSheet.vue';
import QuestionSheet from '@/components/QuestionSheet.vue';
import ChoiceInput from '@/components/ChoiceInput.vue';
import ZaloIcon from '@/components/ZaloIcon.vue';
import ProductItemSheet from '@/components/ProductItemSheet.vue';
import BankList from '@/components/BankList.vue';
import CustomPaymentSheet from '@/components/CustomPaymentSheet.vue';
import ConfettiSuccess from './components/ConfettiSuccess.vue';
import LogoGalleryDialog from './components/LogoGalleryDialog.vue';

import draggable from 'vuedraggable';
import phoneNoFilter from '@/filters/phoneNoFilter';
import vndFilter from '@/filters/vndFilter';
import free20Filter from '@/filters/free20Filter';

Vue.config.productionTip = false;
Vue.use(VuetifyAsyncValidation);
Vue.use(VueFilterDateFormat, {
  dayOfWeekNames: [
    'Chủ Nhật',
    'Thứ Hai',
    'Thứ Ba',
    'Thứ Tư',
    'Thứ Năm',
    'Thứ Sáu',
    'Thứ Bảy',
  ],
  dayOfWeekNamesShort: ['CN', 'Th 2', 'Th 3', 'Th 4', 'Th 5', 'Th 6', 'Th 7'],
  monthNames: [
    'Tháng Một',
    'Tháng Hai',
    'Tháng Ba',
    'Tháng Tư',
    'Tháng Năm',
    'Tháng Sáu',
    'Tháng Bảy',
    'Tháng Tám',
    'Tháng Chín',
    'Tháng Mười',
    'Tháng Mười Một',
    'Tháng Mười Hai',
  ],
  monthNamesShort: [
    'thg 1',
    'thg 2',
    'thg 3',
    'thg 4',
    'thg 5',
    'thg 6',
    'thg 7',
    'thg 8',
    'thg 9',
    'thg 10',
    'thg 11',
    'thg 12',
  ],
  // Timezone offset, in minutes (0 - UTC, 180 - Russia, undefined - current)
  timezone: 420,
});

Vue.component('upload-image-button', UploadImageButton);
Vue.component('upload-image-sheet', UploadImageSheet);
Vue.component('thumbnail-with-delete', ThumbnailWithDelete);
Vue.component('reset-dialog', ResetConfirmationDialog);
Vue.component('feature-in-progress-banner', FeatureInProgressBanner);
Vue.component('draggable', draggable);
Vue.component('variant-sheet', VariantSheet);
Vue.component('question-sheet', QuestionSheet);
Vue.component('choice-input', ChoiceInput);
Vue.component('zalo-icon', ZaloIcon);
Vue.component('product-item-sheet', ProductItemSheet);
Vue.component('bank-list', BankList);
Vue.component('custom-payment-sheet', CustomPaymentSheet);
Vue.component('confetti-success', ConfettiSuccess);
Vue.component('logo-gallery-dialog', LogoGalleryDialog);

router.onError((error) => {
  Vue.prototype.$log.error('Failure Reason: ', error.message, error);
  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    Vue.prototype.$log.error('Reloading Window 1');
    window.location.reload();
  } else if (/Loading.*chunk.*failed./i.test(error.message)) {
    Vue.prototype.$log.error('Reloading Window 2');
    window.location.reload();
  }
});

new Vue({
  vuetify,
  router,
  store,
  filters: {
    phoneNoFilter,
    vndFilter,
    free20Filter,
  },
  render: (h) => h(App),
}).$mount('#app');
