export const getLocalToken = () => localStorage.getItem('token');

export const saveLocalToken = (token: string) =>
  localStorage.setItem('token', token);

export const removeLocalToken = () => localStorage.removeItem('token');

import {
  CF_IMAGES_ACC_HASH,
  IMAGES_VARIANT_AVATAR,
  IMAGES_VARIANT_ICON,
  IMAGES_VARIANT_PUBLIC,
  IMAGES_VARIANT_THUMBNAIL,
} from '@/env';

export const getImageUrl = (
  uuid: string | undefined,
  type: string | undefined
) => {
  if (!uuid) {
    return '';
  }
  if (!type || type === '') {
    // default ge tthe public image
    return `https://imagedelivery.net/${CF_IMAGES_ACC_HASH}/${uuid}/${IMAGES_VARIANT_PUBLIC}`;
  } else {
    switch (type) {
      case 'thumbnail':
        return `https://imagedelivery.net/${CF_IMAGES_ACC_HASH}/${uuid}/${IMAGES_VARIANT_THUMBNAIL}`;
      case 'public':
        return `https://imagedelivery.net/${CF_IMAGES_ACC_HASH}/${uuid}/${IMAGES_VARIANT_PUBLIC}`;
      case 'avatar':
        return `https://imagedelivery.net/${CF_IMAGES_ACC_HASH}/${uuid}/${IMAGES_VARIANT_AVATAR}`;
      case 'icon':
        return `https://imagedelivery.net/${CF_IMAGES_ACC_HASH}/${uuid}/${IMAGES_VARIANT_ICON}`;
      default:
        return `https://imagedelivery.net/${CF_IMAGES_ACC_HASH}/${uuid}/${IMAGES_VARIANT_PUBLIC}`;
    }
  }
};

export const getKeyByValue = (object: any, value: any) => {
  return Object.keys(object).find((key) => object[key] === value);
};

interface DateUtils {
  formatDate(date: Date, showTime: boolean): string;
}

interface TranslationUtils {
  translate(key: string): string;
}

class Utils {
  static Date: DateUtils = {
    formatDate(date: Date, showTime: boolean): string {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      if (!showTime) {
        return `${day}/${month}/${year}`;
      } else {
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
      }
    },
  };

  static Translation: TranslationUtils = {
    translate(key: string): string {
      // replace this with your translation logic
      const translations: Record<string, string> = {
        paid: 'Đã thanh toán',
        unpaid: 'Chưa thanh toán',
        pending: 'Đang chờ',
      };
      return translations[key] || key;
    },
  };
}

export default Utils;
