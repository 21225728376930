export const OrderStatuses = Object.freeze({
  CANCELLED: 'Đã hủy',
  FULFILLED: 'Đã giao hàng',
  PAID: 'Đã thanh toán',
  ARCHIVED: 'Đã lưu trữ',
  REJECTED: 'Đã từ chối',
  UNPAID: 'Chưa thanh toán',
  INBOX: 'Đã nhận',
  REFUNDED: 'Đã hoàn tiền',
});

export const PaymentTypes = Object.freeze({
  ADMIN_ADDED: 'Thêm bởi chủ shop',
  CASH: 'Tiền mặt',
  BANK: 'Chuyển khoản',
  CARD: 'Thẻ tín dụng',
  MOMO: 'Momo',
  ZALOPAY: 'Zalopay',
  VNPAY: 'VNPay',
  QRPAY: 'QRPay',
  CUSTOM: 'Tuỳ chỉnh',
});

export const AllowedChoiceSelection = Object.freeze({
  ONE: 'Một',
  UNLIMITED: 'Không giới hạn',
  EXACT_NUMBER: 'Số lượng chính xác',
  MININUM_NUMBER: 'Số lượng tối thiểu',
  RANGE: 'Phạm vi',
});

export const Constant = Object.freeze({
  UNCATEGORIZED: 'Chưa phân loại',
  DEFAULT_CATEGORY_ID: 'e03322e0-2fc2-4668-82d5-9dd499e3968e',
});

export const DiscountTypes = Object.freeze({
  PERCENT_OFF: 'Phần trăm giảm giá',
  OFF_TOTAL: 'Giảm giá tổng cộng',
  OFF_EACH: 'Giảm giá mỗi sản phẩm',
});
