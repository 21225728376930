<template>
  <div>
    <v-card flat outlined>
      <v-container>
        <v-row class="mt-4 mx-2">
          <v-col cols="12">
            <v-row class="font-weight-bold mb-2"
              >Phương thức thanh toán {{ paymentKey + 1 }}</v-row
            >
            <v-row>
              <v-text-field
                label="Tên phương thức thanh toán"
                placeholder="Ví dụ: Ví Momo ..."
                v-model="paymentMethod.provider_name"
                outlined
                single-line
                required
                :rules="requiredRules"
              ></v-text-field
            ></v-row>
            <v-row>
              <v-text-field
                outlined
                label="Số tài khoản, tên tài khoản, hướng dẫn v.v.."
                v-model="paymentMethod.payment_account"
                single-line
                :rules="requiredRules"
                required
              ></v-text-field>
            </v-row>
            <v-row class="my-2 font-weight-bold"
              ><v-btn color="red" outlined @click="deleteMe">
                <v-icon>{{ svgPath.mdiDelete }}</v-icon>
                Xoá bỏ</v-btn
              >
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model } from 'vue-property-decorator';
import { IPaymentMethod } from '@/interfaces';
import { mdiDelete } from '@mdi/js';
@Component
export default class CustomPaymentSheet extends Vue {
  @Model() public paymentMethod!: IPaymentMethod;
  @Prop(Number) public paymentKey!: number;
  @Prop(Number) public paymentIndex!: number;

  requiredRules = [(v: string) => !!v || 'Hãy nhập giá trị'];

  svgPath = {
    mdiDelete,
  };

  mounted() {}

  reset() {}

  deleteMe() {
    this.paymentMethod.is_deleted = false;
    this.$emit('delete', this.paymentIndex);
  }
}
</script>
