import { vi } from 'vuetify/src/locale';

export default {
  ...vi,
  bundles: 'Các gói sản phẩm',
  bundle: 'Gói sản phẩm',
  create: 'Tạo mới',
  categories: 'Các danh mục',
  category: 'Danh mục',
  completed: 'Hoàn thành',
  order: 'Đơn hàng',
  orders: 'Các đơn hàng',
  product: 'Sản phẩm',
  promotion: 'Khuyến mãi',

  create_shop_page: {
    order_our: 'Đặt hàng',
    welcome_to: 'Chào mừng đến với',
  },

  main: {
    create: 'key 3 internationalization',
  },
};
