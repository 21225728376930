<template>
  <v-overlay value="true" opacity="0.2" z-index="0">
    <v-row justify="center" align="center" class="ma-6 pa-2 white lighten-5">
      <v-icon slot="icon" color="warning" size="36">
        {{ svgPath.mdiAlertBoxOutline }}
      </v-icon>
      <span class="inline text-lg font-large primary--text"
        >Tính năng này hiện đang được hoàn thiện. Chúng tôi sẽ thông báo cho bạn
        khi sẵn sàng. </span
      ><v-icon slot="icon" color="warning" size="36">
        {{ svgPath.mdiAlertBoxOutline }}
      </v-icon>
      <v-btn class="ml-2" color="primary lighten-1" @click="goBack"
        >Về trang trước</v-btn
      >
    </v-row></v-overlay
  >
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { mdiAlertBoxOutline } from '@mdi/js';
@Component
export default class FeatureInProgressBanner extends Vue {
  sticky: boolean = true;
  svgPath = {
    mdiAlertBoxOutline,
  };
  goBack() {
    this.$router.back();
  }
}
</script>
