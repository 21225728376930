export interface IUserProfile {
  email: string;
  is_active: boolean;
  is_superuser: boolean;
  full_name: string;
  id: string;
}

export interface IUserProfileUpdate {
  email?: string;
  full_name?: string;
  password?: string;
  is_active?: boolean;
  is_superuser?: boolean;
  token?: string;
}

export interface IUserProfileCreate {
  email: string;
  full_name?: string;
  password?: string;
  is_active?: boolean;
  is_superuser?: boolean;
}

export interface IShopSetting {
  allow_date_selection?: boolean;
  allow_gift_receipient?: boolean;
  gift_message_enabled?: boolean;
  gift_message?: string;
  additional_instruction_enabled?: boolean;
  additional_instruction?: string;
  allow_private_mode?: boolean;
  private_access_password?: string;
  shop_name?: string;
  shop_address_enabled?: boolean;
  shop_address_main?: string;
  shop_address_secondary?: string;
  contact_info?: string;
  whatsapp_number?: string;
  zalo_number?: string;
  browser_notification_enabled?: boolean;
  domain_name?: string;
  domain_qr_code_uuid?: string;
  sub_domain_name?: string;
  email?: string;
  use_zalo_for_ordering?: boolean;
  fb_messenger_account_id?: string;
  use_fb_messenger_for_ordering?: boolean;
  logo_url?: string;
  welcome_title?: string;
  welcome_message?: string;
  order_now_button_text?: string;
  color_theme?: string;
  email_logo_enabled?: boolean;
  email_logo_url?: string;
  show_about_us_page?: boolean;
  about_us_page_title?: string;
  about_us_page_content?: string;
}

export interface IShop {
  email: string;
  sub_domain_name: string;
  shop_name: string;
  id: string;
}

export interface IShopUpdate {
  email?: string;
  sub_domain_name?: string;
  shop_name?: string;
}

export interface IShopCreate {
  email: string;
  sub_domain_name: string;
  shop_name: string;
  allow_date_selection: boolean;
  product: IProductCreate;
  fulfillment_templates?: IFulfillmentTemplate[];
}

export interface IBundle {
  name: string;
  description?: string;
  is_enable_description?: boolean;
  unit_price: number;
  is_restrict_quantity_choice?: boolean;
  restricted_quantity_choices?: string;
  hidden?: boolean;
  images: IProductPhoto[];
  bundled_items: IBundleItem[];
  id: string;
  questions?: IQuestion[];
  categories?: ICategory[];
  slug?: string;
  is_deleted?: boolean;
}

export interface IBundleItem extends IProduct {
  quantity: number;
  is_deleted?: boolean;
}

export interface IBundleProductCreate {
  id?: string;
  quantity: number;
  product_id?: string;
}

export interface IBundleCreate {
  name: string;
  description?: string;
  unit_price: number;
  productIds?: number[];
  is_restrict_quantity_choice?: boolean;
  restricted_quantity_choices?: string;
  hidden?: boolean;
  images: IProductPhotoCreate[];
  questions?: IQuestion[];
  is_enable_description?: boolean;
  slug?: string;
  is_deleted?: boolean;
  bundled_items?: IBundleProductCreate[];
}

export interface IBundleUpdate {
  name?: string;
  description?: string;
  unit_price?: number;
  variants?: IProductVariantCreate[];
  is_restrict_quantity_choice?: boolean;
  hidden?: boolean;
  images?: IProductPhotoCreate[];
  questions?: IQuestion[];
  is_enable_description?: boolean;
  slug?: string;
  is_deleted?: boolean;
}

export interface IBundleProduct {
  id?: string;
  product_id: string;
  bundle_id?: string;
  quantity: number;
  is_deleted?: boolean;
}

export interface IProductBase {
  name?: string;
  description?: string;
  is_enable_description?: boolean;
  unit_price?: number;
  is_restrict_quantity_choice?: boolean;
  restricted_quantity_choices?: string;
  hidden?: boolean;
  questions?: IQuestion[];
  slug?: string;
  is_deleted?: boolean;
}

export interface IProduct extends IProductBase {
  id?: string;
  is_bundle?: boolean;
  variants: IProductVariant[];
  images: IProductPhoto[];
  questions?: IQuestion[];
  is_deleted?: boolean;
}

export interface IProductCreate extends IProductBase {
  id?: string;
  variants?: IProductVariantCreate[];
  images: IProductPhotoCreate[];
}

export interface IProductUpdate extends IProductBase {
  variants?: IProductVariantCreate[];
  images?: IProductPhotoCreate[];
  is_deleted?: boolean;
}

export interface IProductVariant {
  id?: string;
  temp_id?: string;
  product_id?: string;
  description: string;
  unit_price: number;
  name: string;
  image?: any; // for ui logic
  images: IPhoto[];
  enableDescription?: boolean;
  is_deleted?: boolean;
}

export interface IProductVariantCreate {
  description: string;
  unit_price: number;
  name: string;
  images: IPhoto[];
  is_deleted?: boolean;
}

export interface ICategoryPhoto extends IPhoto {
  id?: string;
  is_deleted?: boolean;
}
export interface IProductPhoto extends IPhoto {
  id?: string;
}

export interface IProductPhotoCreate extends IProductPhoto {
  uuid: string;
}

export interface IBundlePhoto extends IPhoto {
  id?: string;
}

export interface IBundlePhotoCreate extends IProductPhoto {
  uuid: string;
}

export interface IVariantPhoto extends IPhoto {
  id: string;
}

export interface IVariantPhotoCreate {
  uuid: string;
  is_deleted?: boolean;
}

export interface IPhoto {
  temporary?: boolean;
  uuid?: string;
  url?: string;
  is_deleted?: boolean;
}

export interface IQuestion {
  id?: string;
  content: string;
  multiple_choice: boolean;
  allowed_selection: string;
  choices?: IChoice[];
  answer_required: boolean;
  is_deleted?: boolean;
}

export interface IChoice {
  id?: string;
  content: string;
  surcharge?: number | null;
  is_deleted?: boolean;
}

export enum EAllowedChoiceSelection {
  One = 'Một',
  Unlimited = 'Không giới hạn',
  ExactNumber = 'Số lượng cụ thể',
  MinimumNumber = 'Số lượng tối thiểu',
  Range = 'Khoảng số lượng',
}

export enum EQuestionType {
  Text = 'Trả lời bằng nhập chữ',
  Multi = 'Trả lời bằng lựa chọn',
}

export interface IOrder {
  id?: string;
  payment_method_id?: string;
  fulfillment_id?: string;
  public_id?: string;
  status?: string;
  created_at: string;
  buyer_email?: string;
  buyer_name?: string;
  buyer_phone?: string;
  checked_out?: boolean;
  is_gift?: boolean | null;
  payment_name?: string;
  coupon?: string;
  fulfillment_fee?: number;
  total_price?: number;
  total_discount?: number;
  recipient_name?: string;
  recipient_phone?: string;
  tax?: number;
  tip_percentage_applied?: number;
  is_manual_payment?: boolean;
  paid_at?: string | null;
  cancelled_at?: string | null;
  fulfilled_at?: string | null;
  rejected_at?: string | null;
  is_action_required?: boolean;
  cart?: IShoppingCart;
  payment_method?: IPaymentMethod;
  fulfillment?: IFulfillment;
  is_deleted?: boolean;
}

export interface IOrderCreate {
  payment_method_id?: string;
  fulfillment_id?: string;
  address?: string;
  public_id?: string;
  status?: string;
  created_at?: string;
  buyer_email?: string;
  buyer_name?: string;
  buyer_phone?: string;
  checked_out?: boolean;
  is_gift?: boolean | null;
  payment_name?: string;
  coupon?: string;
  fulfillment_fee?: number;
  total_price?: number;
  total_discount?: number;
  recipient_name?: string | null;
  recipient_phone?: string | null;
  tax?: number;
  tip_percentage_applied?: number;
  is_manual_payment?: boolean;
  is_action_required?: boolean;
  cart?: IShoppingCart;
  payment_method?: IPaymentMethod;
  fulfillment?: IFulfillment;
  is_deleted?: boolean;
}

export interface IOrderUpdate {
  id?: string;
  payment_method_id?: string;
  fulfillment_id?: string;
  public_id?: string;
  address?: string;
  status?: string;
  created_at?: string;
  buyer_email?: string;
  buyer_name?: string;
  buyer_phone?: string;
  checked_out?: boolean;
  is_gift?: boolean | null;
  payment_name?: string;
  coupon?: string;
  fulfillment_fee?: number;
  total_price?: number;
  total_discount?: number;
  recipient_name?: string;
  recipient_phone?: string;
  tax?: number;
  tip_percentage_applied?: number;
  is_manual_payment?: boolean;
  paid_at?: string | null;
  cancelled_at?: string | null;
  fulfilled_at?: string | null;
  rejected_at?: string | null;
  is_action_required?: boolean;
  cart?: IShoppingCart;
  payment_method?: IPaymentMethod;
  fulfillment?: IFulfillment;
  is_deleted?: boolean;
}

export interface IShoppingCart {
  id?: string;
  total_discount?: number;
  total_price?: number;
  cart_items: IShoppingCartItem[];
  cart_items_summary?: string;
}
export interface IShoppingCartItem {
  id?: string;
  cart_id?: string;
  product_id: string;
  product_name?: string;
  quantity: number;
  variant_id?: string;
  unit_price?: number;
  total_price?: number;
  total_discount?: number;
  thumbnail_url?: string;
  product?: IProduct;
  customizations?: IItemCustomization[];
  created_time?: number;
  is_deleted?: boolean;
}

export interface IItemCustomization {
  id?: string;
  cart_item_id?: string;
  choice_id?: string;
  question_id?: string;
  question_content?: string;
  answer_content?: string;
  answer_enabled?: boolean;
  selected?: boolean;
  quantity: number;
  total_price: number;
  surcharge_enabled?: boolean;
  choice?: IChoice;
  is_deleted?: boolean;
}

export interface IFulfillment {
  id?: string;
  fulfillment_name?: string;
  fulfillment_template_id?: string;
  is_pick_up?: boolean;
  promotion_id?: string;
  address?: string;
  instruction?: string;
  fulfillment_fee_enabled?: boolean;
  fulfillment_fee?: number;
  is_quote_after_order?: boolean;
  minimum_spend_enabled?: boolean;
  minimum_spend_for_free_fulfillment?: number;
  is_deleted?: boolean;
  fulfillment_template?: IFulfillmentTemplate;
}

export interface IFulfillmentTemplate {
  id?: string;
  temp_id?: string;
  fulfillment_name?: string;
  is_pick_up?: boolean;
  instruction_enabled?: boolean;
  address?: string;
  instruction?: string;
  fulfillment_fee_enabled?: boolean;
  fulfillment_fee?: number;
  fulfillment_fee_type?: string;
  is_quote_after_order?: boolean;
  minimum_spend_enabled?: boolean;
  is_deleted?: boolean;
  minimum_spend_for_free_fulfillment?: number;
}

export interface IPaymentMethod {
  id?: string;
  payment_type?: string;
  payment_name: string;
  payment_account: string;
  payment_instruction?: string;
  is_enabled?: boolean;
  is_deleted?: boolean;
  is_automated?: boolean;
  is_minimum_spend_required?: boolean;
  minimum_spend_value?: number;
  provider_name?: string;
  show_qr_code?: boolean;
}

export interface ICategory {
  id?: string;
  name?: string;
  is_deleted?: boolean;
  products?: IProduct[];
  category_photos?: ICategoryPhoto[];
}

export interface ICategoryCreate {
  name: string;
  is_deleted?: boolean;
  products?: IProduct[];
  category_photos: IProductPhoto[];
}

export interface ICategoryUpdate {
  id?: string;
  name?: string;
  is_deleted?: boolean;
  products?: IProduct[];
  category_photos: IProductPhoto[];
}

export interface ICalendar {
  id?: string;
  date: string;
  is_blocked: boolean;
  is_deleted?: boolean;
  inventories?: IInventory[];
}

export interface IInventory {
  id?: string;
  quantity_left?: number | string;
  product_id?: string;
  calendar_id?: string;
  is_deleted?: boolean;
  product?: IProduct;
}

export interface IInvoice {
  id?: string;
  invoice_number?: string;
  invoice_date?: string;
  due_date?: string;
  status?: string;
  amount?: number;
  invoice_url?: string;
  invoice_pdf_url?: string;
  is_deleted?: boolean;
  invoice_line_items: IInvoiceLineItems[];
}

export interface IInvoiceLineItems {
  id?: string;
  invoice_id?: string;
  line_amount?: number;
  is_deleted?: boolean;
}

export interface IPromotion {
  id?: string;
  coupon_code?: string;
  discount_type?: string;
  discount_value?: number;
  applies_to?: string;
  is_min_purchase_required?: boolean;
  min_purchase_amount?: number;
  is_one_use_per_buyer?: boolean;
  is_redemption_limit?: boolean;
  redemption_limit?: number;
  has_activate_date?: boolean;
  activate_date_start?: string;
  activate_date_end?: string;
  is_deleted?: boolean;
  products?: IProduct[];
  fulfillments?: IFulfillment[];
}

export interface IPlan {
  id?: string;
  service_id?: string;
  billing_interval?: number;
  access_level?: number;
  created_at?: string;
  deleted_at?: string;
  plan_code?: string;
  is_deleted?: boolean;
  plan_pricings?: IPlanPricing[];
  service?: IService;
}

export interface IPlanPricing {
  id?: string;
  price?: number;
  from_date?: string;
  to_date?: string;
  created_at?: string;
  deleted_at?: string;
  is_deleted?: boolean;
}

export interface IService {
  name?: string;
  id?: string;
  description?: string;
  created_at?: string;
  deleted_at?: string;
}

export interface ISubscription {
  id?: string;
  starts_at?: string;
  ends_at?: string;
  plan?: IPlan;
}
