import {
  IUserProfile,
  IProduct,
  ICategory,
  IOrder,
  IPaymentMethod,
  IPlan,
} from '@/interfaces';
import { AdminState } from '@/store/admin/state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '@/store/state';

export const mutations = {
  setUsers(state: AdminState, payload: IUserProfile[]) {
    state.users = payload;
  },
  setUser(state: AdminState, payload: IUserProfile) {
    const users = state.users.filter(
      (user: IUserProfile) => user.id !== payload.id
    );
    users.push(payload);
    state.users = users;
  },
  setProducts(state: AdminState, payload: IProduct[]) {
    state.products = payload;
  },
  setVariantsProducts(state: AdminState, payload: IProduct[]) {
    state.variantProducts = payload;
  },
  setProduct(state: AdminState, payload: IProduct) {
    const products = state.products.filter(
      (product: IProduct) => product.id !== payload.id
    );
    products.push(payload);
    state.products = products;
  },
  deleteProduct(state: AdminState, productId: string) {
    const products = state.products.filter(
      (product: IProduct) => product.id !== productId
    );
    state.products = products;
  },
  setOrders(state: AdminState, payload: IOrder[]) {
    state.orders = payload;
  },
  setOrder(state: AdminState, payload: IOrder) {
    const oldOrders = state.orders.filter(
      (order: IOrder) => order.id === payload.id
    );
    if (oldOrders.length > 0 && !payload.cart) {
      payload.cart = oldOrders[0].cart;
      // replace the old order with the new one at the same index
      state.orders.splice(state.orders.indexOf(oldOrders[0]), 1, payload);
    } else {
      const orders = state.orders.filter(
        (order: IOrder) => order.id !== payload.id
      );
      orders.push(payload);
      state.orders = orders;
    }
  },
  setOrderMeta(state: AdminState, payload: IOrder) {
    const orders = state.orders.filter(
      (order: IOrder) => order.id !== payload.id
    );
    orders.push(payload);
    state.orders = orders;
  },

  setPlans(state: AdminState, payload: IPlan[]) {
    state.plans = payload;
  },

  setPlan(state: AdminState, payload: IPlan) {
    const plans = state.plans.filter((plan: IPlan) => plan.id !== payload.id);
    plans.push(payload);
    state.plans = plans;
  },

  setInvoices(state: AdminState, payload: any) {
    state.invoices = payload;
  },

  setSubscription(state: AdminState, payload: IPlan) {
    state.activeSubscription = payload;
  },

  setFulfillmentTemplates(state: AdminState, payload: any) {
    state.fulfillmentTemplates = payload;
  },
  setPaymentMethods(state: AdminState, payload: IPaymentMethod[]) {
    state.paymentMethods = payload;
  },
  setCategories(state: AdminState, payload: ICategory[]) {
    state.categories = payload;
  },
  setCategory(state: AdminState, payload: ICategory) {
    const categories = state.categories.filter(
      (category: ICategory) => category.id !== payload.id
    );
    categories.push(payload);
    state.categories = categories;
  },
  deleteCategory(state: AdminState, categoryId: string) {
    const categories = state.categories.filter(
      (category: ICategory) => category.id !== categoryId
    );
    state.categories = categories;
  },
  setBundles(state: AdminState, payload: any) {
    state.bundles = payload;
  },
  setBundle(state: AdminState, payload: any) {
    const bundles = state.bundles.filter(
      (bundle: any) => bundle.id !== payload.id
    );
    bundles.push(payload);
    state.bundles = bundles;
  },
  deleteBundle(state: AdminState, bundleId: string) {
    const bundles = state.bundles.filter(
      (bundle: any) => bundle.id !== bundleId
    );
    state.bundles = bundles;
  },
  setCalendars(state: AdminState, payload: any) {
    // insert payload to calendars array
    for (const updatedCalendar of payload) {
      const calendars = state.calendars.filter(
        (cal: any) => cal.date !== updatedCalendar.date
      );
      calendars.push(updatedCalendar);
      state.calendars = calendars;
    }
  },
  setCalendar(state: AdminState, payload: any) {
    const calendars = state.calendars.filter(
      (calendar: any) => calendar.id !== payload.id
    );
    calendars.push(payload);
    state.calendars = calendars;
  },
  setShopSetting(state: AdminState, payload: any) {
    state.shopSetting = payload;
  },
};

const { commit } = getStoreAccessors<AdminState, State>('');

export const commitSetUser = commit(mutations.setUser);
export const commitSetUsers = commit(mutations.setUsers);
export const commitSetProducts = commit(mutations.setProducts);
export const commitSetVariantsProducts = commit(mutations.setVariantsProducts);
export const commitSetProduct = commit(mutations.setProduct);
export const commitDeleteProduct = commit(mutations.deleteProduct);
export const commitSetOrder = commit(mutations.setOrder);
export const commitSetOrders = commit(mutations.setOrders);
export const commitSetPlans = commit(mutations.setPlans);
export const commitSetInvoices = commit(mutations.setInvoices);
export const commitSetPlan = commit(mutations.setPlan);
export const commitSetSubscription = commit(mutations.setSubscription);
export const commitSetOrderMeta = commit(mutations.setOrderMeta);
export const commitSetFulfillmentTemplates = commit(
  mutations.setFulfillmentTemplates
);
export const commitSetPaymentMethods = commit(mutations.setPaymentMethods);
export const commitSetCategories = commit(mutations.setCategories);
export const commitSetCategory = commit(mutations.setCategory);
export const commitDeleteCategory = commit(mutations.deleteCategory);
export const commitSetBundle = commit(mutations.setBundle);
export const commitSetBundles = commit(mutations.setBundles);
export const commitDeleteBundle = commit(mutations.deleteBundle);
export const commitSetCalendar = commit(mutations.setCalendar);
export const commitSetCalendars = commit(mutations.setCalendars);
export const commitSetShopSetting = commit(mutations.setShopSetting);
