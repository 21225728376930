import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { AdminState } from './state';

const defaultState: AdminState = {
  users: [],
  shops: [],
  products: [],
  variantProducts: [],
  orders: [],
  plans: [],
  fulfillmentTemplates: [],
  paymentMethods: [],
  categories: [],
  bundles: [],
  calendars: [],
  promotions: [],
  shopSetting: undefined,
  activeSubscription: {},
  invoices: [],
};

export const adminModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
