<template>
  <div>
    <v-card>
      <v-container>
        <v-row class="mx-4 mt-2">
          <v-col cols="12">
            <v-row class="font-weight-bold">
              <v-icon class="mr-2">{{ svgPath.mdiFormatFont }}</v-icon
              >Mặt hàng {{ itemId + 1 }}</v-row
            >
            <v-row class="mt-6" v-if="populatedCartItem">
              <v-select
                outlined
                v-model="populatedCartItem.product"
                :items="selectionItems"
                style="width: 210px"
                @change="onItemSelect"
                return-object
                item-text="name"
              >
                <template v-slot:selection="slotProps">
                  <v-avatar
                    ><v-img :src="getItemIcon(slotProps.item)" contain> </v-img
                  ></v-avatar>
                  <span class="px-2">
                    {{ slotProps.item.name }}
                  </span>
                </template>
                <template v-slot:item="slotProps">
                  <v-list-item-avatar>
                    <v-img :src="getItemIcon(slotProps.item)" contain> </v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <span>{{ slotProps.item.name }}</span>
                  </v-list-item-content>
                </template>
              </v-select></v-row
            >
          </v-col>
        </v-row>

        <v-row class="mx-4">
          <v-col cols="12">
            <v-row class="font-weight-bold">
              <v-icon class="mr-2">{{ svgPath.mdiCurrencyUsd }}</v-icon>
              Đơn giá</v-row
            >
            <v-row class="mt-6">
              <vuetify-money
                v-model="populatedCartItem.unit_price"
                v-bind:options="unitPriceOptions"
                v-bind:properties="moneyProperties"
                :rules="priceRules"
                v-bind:placeholder="moneyPlaceholder"
                v-bind:clearable="moneyClearable"
                outlined
                style="width: 100%"
              />
            </v-row>
          </v-col>
        </v-row>

        <template
          v-for="(customization, index) in populatedCartItem.customizations"
        >
          <v-row
            class="mx-4"
            :class="customization.answer_enabled ? 'mt-2 mb-0' : 'mt-4 mb-6'"
            :key="index"
          >
            <v-col cols="12">
              <v-row class="font-weight-bold">
                <v-icon class="mr-2">{{ svgPath.mdiFormatFont }}</v-icon>
                {{ index + 1 }}</v-row
              >
              <v-row class="mt-6 mb-0">
                {{ customization.question_content }}</v-row
              >
              <v-row
                ><v-switch
                  class="ma-0 pa-0"
                  v-model="customization.answer_enabled"
                  color="primary"
                  label="Add answer"
                  hide-details="true"
                ></v-switch
              ></v-row>
              <v-row class="mt-6" v-if="customization.answer_enabled">
                <v-text-field
                  v-model="customization.answer_content"
                  :rules="descriptionRules"
                  single-line
                  outlined
                  required
                ></v-text-field>
              </v-row>
              <v-row v-if="customization.answer_enabled"
                ><v-switch
                  class="ma-0 pa-0"
                  v-model="customization.surcharge_enabled"
                  color="primary"
                  label="Surcharge"
                  hide-details="true"
                ></v-switch
              ></v-row>
              <v-row class="mt-6" v-if="customization.surcharge_enabled">
                <vuetify-money
                  v-model="customization.total_price"
                  v-bind:options="moneyOptions"
                  v-bind:properties="moneyProperties"
                  :rules="priceRules"
                  v-bind:placeholder="moneyPlaceholder"
                  v-bind:clearable="moneyClearable"
                  outlined
                  style="width: 100%"
                  class="mt-2 mb-4"
                />
              </v-row>
            </v-col>
          </v-row>
        </template>

        <v-row class="mx-4">
          <v-col cols="12">
            <v-row class="font-weight-bold">
              <v-icon class="mr-2">{{ svgPath.mdiPointOfSale }}</v-icon>
              Số lượng</v-row
            >
            <v-row class="mt-6">
              <v-text-field
                v-model="populatedCartItem.quantity"
                :rules="quantityRules"
                single-line
                @change="calculateTotalPrice"
                outlined
                required
                type="number"
              ></v-text-field>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="mx-4 my-4">
          <v-col cols="12">
            <v-row class="font-weight-bold"
              ><v-icon class="mr-2">{{ svgPath.mdiTagHeart }}</v-icon
              >Giảm giá<v-spacer />
              <v-switch
                class="ma-0 pa-0"
                v-model="discountEnabled"
                color="primary"
                hide-details="true"
              ></v-switch
            ></v-row>
            <v-row class="mt-6" v-if="discountEnabled">
              <vuetify-money
                v-model="orderDiscount"
                v-bind:options="discountMoneyOptions"
                v-bind:properties="moneyProperties"
                :rules="priceRules"
                v-bind:placeholder="moneyPlaceholder"
                v-bind:clearable="moneyClearable"
                outlined
                style="width: 100%"
                class="mt-2 mb-4"
              />
            </v-row>
          </v-col>
        </v-row>

        <v-row class="ma-2" v-if="totalItemCount > 1">
          <v-col cols="12">
            <v-row align="start">
              <v-col cols="12">
                <v-row class="font-weight-bold"
                  ><v-btn color="red" outlined @click="deleteMe">
                    <v-icon>{{ svgPath.mdiDelete }}</v-icon>
                    Xoá bỏ</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model, Watch } from 'vue-property-decorator';
import {
  IProductVariant,
  IProduct,
  IPhoto,
  IQuestion,
  IShoppingCartItem,
} from '@/interfaces';
import {
  mdiFormatFont,
  mdiPointOfSale,
  mdiCurrencyUsd,
  mdiDelete,
  mdiTagHeart,
} from '@mdi/js';
import { readAdminOneProduct, readAdminProducts } from '@/store/admin/getters';
import { dispatchGetProducts } from '@/store/admin/actions';
import { getImageUrl } from '@/utils';
import { IMAGES_VARIANT_ICON } from '@/env';
@Component
export default class ProductItemSheet extends Vue {
  @Prop(String) public color: string | undefined;
  @Prop({ default: 1 }) public totalItemCount!: number;
  @Prop({ default: false }) public multiple!: boolean;
  @Prop(Number) public itemId!: number;
  @Model() public cartItem!: IShoppingCartItem;

  getImageUrl = getImageUrl;

  item: IProduct | IProductVariant | null = null;

  unitPrice: number | null = null;
  discountEnabled: boolean = false;

  unitPriceOptions = {
    locale: 'vi-VN',
    precision: 0,
    suffix: ' ₫ ',
  };

  populatedCartItem: IShoppingCartItem = {
    product_id: '0',
    quantity: 0,
    created_time: Date.now(),
    unit_price: 0,
  };

  @Watch('populatedCartItem')
  onPopulatedCartItemChanged() {
    this.$emit('update:cartItem', this.populatedCartItem);
  }

  itemDescription: string | null = null;
  enableDescription: boolean | null = null;
  moneyPlaceholder = '10.000';
  moneyClearable = true;
  svgPath = {
    mdiFormatFont,
    mdiPointOfSale,
    mdiCurrencyUsd,
    mdiDelete,
    mdiTagHeart,
  };
  orderDiscount: number | null = null;

  get products() {
    return readAdminProducts(this.$store);
  }

  onItemSelect(selectedItem: IProductVariant | IProduct | null) {
    if (!selectedItem) {
      return;
    }
    this.populatedCartItem.product_id = selectedItem.id!;
    this.initCustomizations((selectedItem as IProduct).questions || []);
    this.populatedCartItem.product_name = selectedItem.name!;
    this.populatedCartItem.unit_price = selectedItem.unit_price;
    this.calculateTotalPrice();
  }

  initCustomizations(questions: IQuestion[]) {
    this.populatedCartItem.customizations = [];
    questions.forEach((question) => {
      this.populatedCartItem.customizations!.push({
        question_content: question.content,
        answer_enabled: false,
        total_price: 0,
        quantity: 0,
        question_id: question.id,
      });
    });
  }

  eAllowedChoiceSelectionItems: any[] = [];
  selectionItems: any[] = [];

  getItemIcon(item: IProduct | IProductVariant) {
    let thumbnail: IPhoto;
    // check if item is a variant or product
    if ('images' in item) {
      thumbnail = (item as IProductVariant).images[0];
    } else {
      thumbnail = (item as IProduct).images[0];
    }
    return this.getImageUrl(thumbnail.uuid!, IMAGES_VARIANT_ICON);
  }

  async mounted() {
    if (!this.products || this.products.length === 0) {
      await dispatchGetProducts(this.$store);
    }
    for (const product of this.products) {
      if (product.variants && product.variants.length > 0) {
        for (const variant of product.variants) {
          this.selectionItems.push(variant);
        }
      } else {
        this.selectionItems.push(product);
      }
    }
    this.populatedCartItem = this.cartItem;
    if (
      !this.populatedCartItem.unit_price &&
      this.populatedCartItem.product?.unit_price
    ) {
      this.populatedCartItem.unit_price =
        this.populatedCartItem.product?.unit_price;
    }
  }

  calculateTotalPrice() {
    this.populatedCartItem.total_price =
      this.populatedCartItem.quantity * this.populatedCartItem.unit_price!;
  }

  itemNameRules = [
    (v) => !!v || 'Tên không được để trống',
    (v) => (v && v.length <= 150) || 'Tên phải nhỏ hơn 150 ký tự',
  ];

  descriptionRules = [
    (v) => !!v || 'Mô tả không được để trống',
    (v) => (v && v.length <= 500) || 'Mô tả phải nhỏ hơn 500 ký tự',
  ];

  public priceRules = [
    (v) => !!v || 'Giá không được để trống',
    (v) => {
      const price = Number(v.replace(/[.]/g, ''));
      if (price < 999999999) {
        return true;
      } else {
        return 'Giá phải nhỏ hơn 999.999.999 đ';
      }
    },
  ];

  quantityRules = [
    (v) => !!v || 'Số lượng không được để trống',
    (v) => {
      const quantity = Number(v);
      if (quantity < 999999999) {
        return true;
      } else {
        return 'Số lượng phải nhỏ hơn 999.999.999';
      }
    },
  ];

  public discountMoneyOptions = {
    locale: 'vi-VN',
    suffix: '₫',
    prefix: 'tổng giảm',
    precision: 0,
  };

  public moneyOptions = {
    locale: 'vi-VN',
    suffix: '₫',
    precision: 0,
  };

  public moneyProperties = {
    hint: 'chỉ điền số',
    type: 'tel',
  };

  quantity: number = 1;

  reset() {
    // TODO reset all fields
  }

  deleteMe() {
    this.$emit('delete', this.itemId);
  }
}
</script>

<style scoped>
.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
</style>
